import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../auth";
import {
  Box,
  Chip,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NoDataSvg } from "../../../clinicalNotesClinicalRoute/ClinicalNoteSVGs";

function Appointments({ clientDetails }) {
  const [loading, setLoading] = useState(true);
  const [clientAppointmentsList, setClientAppointmentsList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const { currentUser, userType, logout } = useAuth();

  //Fetch Client Appointments
  useEffect(() => {
    if (!clientDetails?.id) {
      // If checkClientID is blank, return early
      return;
    }

    const payload = {
      expert_id: currentUser?.UserId,
      user_id: Number(clientDetails?.id),
      page: page,
    };

    const postClientAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers: {
              "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { appointments, total_pages } = await postResponse.data;

        setClientAppointmentsList(appointments);
        setTotalPage(total_pages);
        //setUserData(postResponse?.data);
        setLoading(false);
        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientAppointmentsData();
  }, [clientDetails?.id, page]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  return (
    <div className="Tab--Panel Appointments--Panel">
      {/* Appointment */}
      {loading ? (
        Array.from(new Array(10)).map((_, index) => (
          <div
            style={{
              width: "100%",
              height: "70px",
              backgroundColor: "rgb(0,0,0,0.07)",
              padding: "10px",
              borderRadius: "10px",
            }}
            className="d-flex flex-column gap-2"
          >
            <Skeleton
              variant="rounded"
              width={75}
              height={25}
              sx={{ borderRadius: "20px" }}
            />
            <div className="d-flex flex-row gap-3">
              <Skeleton
                variant="rounded"
                width="5vw"
                height={20}
                sx={{ borderRadius: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width="5vw"
                height={20}
                sx={{ borderRadius: "5px" }}
              />
              <Skeleton
                variant="rounded"
                width="5vw"
                height={20}
                sx={{ borderRadius: "5px" }}
              />
            </div>
          </div>
        ))
      ) : clientAppointmentsList?.length > 0 ? (
        <>
          <Table stickyHeader>
            {/* Table Head */}
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Duration</TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {clientAppointmentsList?.map((item) => (
                <TableRow hover key={item.ID}>
                  {/* Status Column */}
                  <TableCell>
                    <Chip
                      label={
                        item?.Status === "Cancelled"
                          ? "Not Completed"
                          : "Completed"
                      }
                      style={{
                        color:
                          item?.Status === "Cancelled" ? "#D92D20" : "#079455",
                        backgroundColor:
                          item?.Status === "Cancelled" ? "#FEE4E2" : "#DCFAE6",
                      }}
                    />
                  </TableCell>

                  {/* Date Column */}
                  <TableCell>
                    <Typography variant="body2">{item?.AppDate}</Typography>
                  </TableCell>

                  {/* Time Column */}
                  <TableCell>
                    <Typography variant="body2">{item?.AppTime}</Typography>
                  </TableCell>

                  {/* Duration Column */}
                  <TableCell>
                    <Typography variant="body2">{item?.duration}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="d-flex justify-content-end w-100">
            <Pagination
              count={totalPage}
              page={page}
              onChange={handleChangePage}
            />
          </div>
        </>
      ) : (
        <div className="NoDataAvailable">
          {NoDataSvg}
          <p> No Appointments Available</p>
        </div>
      )}
    </div>
  );
}

export default Appointments;
