import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Link,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../../context";
import { isAusDomain } from "../clinicalNotesClinicalRoute/lib/utils";

const faqData = {
  general_information: [
    {
      question: "How can Clinical Co-Pilot improve my clinical practice?",
      answer:
        "Clinical Co-pilot is designed to reduce the time you spend on administrative tasks, allowing you to focus more on patient care. It utilizes generative AI to automatically create accurate and up-to-date Clinical Notes from your medical documents and voice inputs. Using a host of AI tools, it also helps you visualize, interface with, and get unique insights into your patient data.",
    },
    {
      question: "Who can benefit from using Clinical Co-Pilot?",
      answer:
        "Clinical Co-pilot is made for a wide range of healthcare professionals, including clinicians, nurses, physician assistants, and administrative staff within a medical practice. The platform is particularly beneficial for those looking to enhance patient engagement, streamline data management, and improve overall efficiency in their medical practice.",
    },
    {
      question: "What features does Clinical Co-Pilot offer?",
      answer:
        "Clinical Co-pilot offers a comprehensive suite of features designed to simplify your workflows. You can create and manage detailed Clinical Notes on your patients efficiently, receive alerts on drug-drug interactions and recommended medications and lab tests, see AI-driven data visualization that helps you better understand trends in your patients' health history, and make use of Ask Stella, our AI assistant, who you can ask questions about the patient's medical information.",
    },
    {
      question: "Can my patient use the United We Care App?",
      answer:
        "Yes, the United We Care app is designed for both clinicians and patients. Your patients can download and use the app to access various services such as booking appointments, communicating with healthcare providers, managing their personal health records, and receiving health-related guidance and support. The app enhances patient engagement and empowers them to take an active role in their healthcare journey. For more details on how patients can benefit from the app, please visit our website or contact our support team.",
    },
    {
      question:
        "If I want to take video consultation through United We Care platform, what should I do?",
      answer:
        "To offer video consultations, please contact our support team to learn about the onboarding process for experts. They will guide you through the steps necessary to get started.",
    },
  ],
  using_the_platform: [
    {
      question: "How do I create and populate a Clinical Note?",
      answer:
        "First, navigate to 'Home' from the left-hand menu and click on the 'Create Clinical Note' button. Next, select a patient to create a note on, or create a new patient. Your Clinical Note has now been created. To populate it, click on the microphone icon with the 'Generate Note via Voice/Upload' pop-up on the right side of the screen. From here, you can upload a medical document by clicking on the 'Upload' button or create a voice input by clicking on the microphone button and talking - a transcript of your document/voice input will be shown on the screen and can be edited. Click 'Send Transcription' to add this information to your note.",
    },
    {
      question: "Can I edit the Clinical Note after it has been populated?",
      answer:
        "Yes, you can freely edit all information in the note at any point until you submit it.",
    },
    {
      question: "How do I navigate the sections in a Clinical Note?",
      answer:
        "To navigate a Clinical Note, use the tabs at the top of the main panel. 'Clinical Advice' lets you review information about the session and make recommendations. 'Clinical Examination' describes all the medical tests taken by the patient. 'Follow-Up Note' charts the patient's progress, and 'Case History' details their medical history.",
    },
    {
      question: "How do I add a client?",
      answer:
        "To add a new client profile, navigate to 'Home' from the left-hand menu, then go to 'Create Clinical Note'. Then, click on the 'Create New Client Profile' button. On the next screen, fill in the patient's details, and click on 'Confirm and Create Clinical Note'. Your patient has now been added, and a clinical note has been created for them.",
    },
  
  ],
  "co-Pilot_features": [
      {
      question: "What is 'Graph' and how do I use it?",
      answer:
        "'Graph' is a visualization tool within Clinical Co-pilot that breaks down complex patient data as intuitive, interactive diagrams based on decades of medical research. To use it, go to a Clinical Note, click on 'Assistant' and navigate to the 'Graph' tab.",
    },
    {
      question: "How does 'Ask Stella' work?",
      answer:
        "'Ask Stella' is an AI-powered assistant integrated into Clinical Co-pilot, designed to provide instant responses to your clinical queries. To use it, go to a Clinical Note, click on 'Assistant' and navigate to the 'Ask Stella' tab.",
    },
    {
      question: "What are 'Alerts' and how can they help me?",
      answer:
        "'Alerts' are notifications about possible drug-drug interactions and comorbidities, or suggestions about lab tests and medications. To see them, go to a Clinical Note and click on the warning icon with the 'Alerts' pop-up on the right side.",
    },
    {
      question:
        "What do I do if the auto-populated information in the Clinical Note is incorrect?",
      answer:
        "If a field in the Clinical Note does not accurately reflect the information in your uploaded document or voice input, you can click on the 'regenerate' icon below it to refresh it, or click on 'dislike' to indicate to us that the response is incorrect. You may also manually edit the field for accuracy.",
    },
  ],

  customer_support_and_feedback: [
    {
      question: "What should I do if I encounter a technical issue?",
      answer:
        "If you experience a technical issue with Clinical Co-pilot, please report the issue to us by raising a ticket. Go to 'Help Center' from the left-hand menu and click on the 'Contact Support' button to start a ticket. Describe your problem in detail here, and submit the ticket. Our support team will reach out to you shortly.",
    },
    {
      question: "Who do I contact if I have trouble logging in?",
      answer:
        "If you have trouble logging in, you can email us directly at copilot@unitedwecare.com with a detailed description of your issue.",
    },
    {
      question: "How do I submit feedback about Clinical Co-Pilot?",
      answer:
        "To submit feedback about Clinical Co-pilot, first navigate to Help Center from the left-hand menu and click on the 'Contact Support' button. Choose 'I want to submit feedback' as the subject for your ticket, and describe any changes you would like to see. We value your input and work constantly to make our product better for our users.",
    },
    {
      question: "How do I submit feedback?",
      answer:
        "To submit feedback about Clinical Co-pilot, first navigate to Help Center from the left-hand menu and click on the 'Contact Support' button. Choose 'I want to submit feedback' as the subject for your ticket, and describe any changes you would like to see. We value your input and work constantly to make our product better for our users.",
    },
  ],
  security_and_accessibility: [
    {
      question: "What security measures are in place to protect my data?",
      answer:
        "Clinical Co-pilot prioritizes the security and confidentiality of your data with robust measures in place. We employ industry-standard encryption protocols to secure data both in transit and at rest, ensuring that all your information is protected against unauthorized access. Additionally, we implement strict access controls, regularly audit our systems, and comply with healthcare regulations like HIPAA to safeguard your privacy. Regular security updates and vulnerability assessments are also conducted to maintain the integrity and resilience of our systems.",
    },
    {
      question:
        "How does Clinical Co-pilot comply with healthcare regulations?",
      answer:
        "Clinical Co-pilot adheres strictly to all healthcare regulations to ensure the privacy and security of patient information. All patient data is encrypted both in transit and at rest, using advanced encryption standards. We also implement strong access controls and authentication measures to ensure that only authorized personnel can access sensitive information. Comprehensive logging and audit trails are maintained to monitor and review access and usage of patient data, and regular risk assessments are conducted to identify and mitigate potential security vulnerabilities. Finally, all staff undergo regular training on compliance and our privacy policy. These measures help us maintain the highest standards of data protection and compliance with healthcare laws, thereby safeguarding the confidentiality, integrity, and availability of patient information.",
    },
    {
      question: "Who has access to my data?",
      answer:
        "Access to your data is strictly controlled and limited to authorized individuals. This includes yourself, people you explicitly grant access to, and a limited number of staff involved in system maintenance and support. Auditors may also access data for compliance reviews, but this is conducted under stringent regulatory oversight and is strictly for ensuring adherence to healthcare regulations. Every access point is logged and monitored to ensure that it complies with our strict privacy and security protocols, safeguarding your information against unauthorized use.",
    },
    {
      question: "How often is Clinical Co-pilot updated?",
      answer:
        "Clinical Co-pilot is updated regularly to ensure optimal performance, security, and functionality. Updates include new features, enhancements, bug fixes, performance improvements, and critical security patches. Routine maintenance updates are also performed to keep the system running smoothly and efficiently.",
    },
    {
      question:
        "Can Clinical Co-pilot integrate with other medical software systems?",
      answer:
        "Yes, Clinical Co-pilot is designed to seamlessly integrate with a variety of medical software systems. For specific integration requirements or to understand how Clinical Co-pilot can be integrated into your existing infrastructure, please contact our support team.",
    },
  ],
  credits: [
    {
      question: "What is the Credit Management System?",
      answer:
        "The Credit Management System is a feature that allocates and tracks credits for clinicians. These credits are used to access various functionalities within Clinical Co-pilot, such as creating and managing clinical notes. The system helps clinicians manage their usage efficiently by providing real-time updates on their credit balance, detailed usage history, and notifications for low balance or credit expiration. Admins can also manage credit policies, allocate additional credits, and adjust credit parameters as needed.",
    },
    {
      question: "How do I receive my initial credits?",
      answer:
        "Upon successful registration and email verification, each clinician is automatically allocated 300 credits. These credits are valid for a period of 3 months from the date of allocation and can be used to access various functionalities within Clinical Co-pilot, such as creating and managing clinical notes. You can view your initial credit balance and usage details on your account dashboard. Please note that credit amounts and timeframes are subject to change as per company policy.",
    },
    {
      question: "How is my credit balance updated?",
      answer:
        "Your credit balance is updated in real time. Each time you create a Clinical Note or use a feature that requires credits, the corresponding number of credits is deducted from your balance immediately. You can view your current credit balance and detailed usage history on your account dashboard, allowing you to keep track of your remaining credits and how they have been utilized.",
    },
    {
      question: "What happens if I run out of credits?",
      answer:
        "If you run out of credits, you will not be able to create new clinical notes or access other functionalities that require credits. To continue using these features, you will need to obtain additional credits. You can contact our support team to request more credits or to inquire about purchasing additional credits. Admins also have the ability to allocate additional credits based on usage or special promotions.",
    },
    {
      question: "Can my unused credits roll over to the next period?",
      answer:
        "Usually, unused credits do not roll over to the next period and expire at the end of their validity. However, the rollover policy is at the discretion of the company and may change. Please refer to the latest terms and conditions or contact support for the most up-to-date information.",
    },
    {
      question: "How do I receive additional credits?",
      answer:
        "You can receive additional credits through various means. Admins have the ability to allocate extra credits based on usage, promotions, or special requests. If you need more credits, you can contact our support team to request additional credits or to inquire about purchasing more. You will be notified via email when additional credits are added to your account.",
    },
    {
      question: "How can I track my credit usage?",
      answer:
        "You can track your credit usage through the 'Credits' section under 'Settings'. This section provides a detailed history of your credit transactions, including the dates, amounts, and the remaining balance after each transaction.",
    },
    {
      question: "Who should I contact for support regarding credits?",
      answer:
        "If you have any questions or issues related to your credits, please contact our support team. You can reach us via email at copilot@unitedwecare.com or call our helpline at 647-688-3658. Our team is available 24/7 to assist you with any credit-related inquiries or concerns.",
    },
  ],
};

const ausFaqData = {
  general_information: [
    {
      question: "How can Clinical Co-Pilot improve my clinical practice?",
      answer:
        "Ask Sam Clinical Co-pilot is designed to reduce the time you spend on administrative tasks, allowing you to focus more on patient care. It utilizes generative AI to automatically create accurate and up-to-date Clinical Notes from your medical documents and voice inputs. Using a host of AI tools, it also helps you visualize, interface with, and get unique insights into your patient data.",
    },
    {
      question: "Who can benefit from using Clinical Co-Pilot?",
      answer:
        "Ask Sam Clinical Co-pilot is made for clinicians and is particularly beneficial for those looking to enhance patient engagement, streamline data management, and improve overall efficiency in their medical practice.",
    },
    {
      question: "What features does Ask Sam Clinical Co-Pilot offer?",
      answer:
        "Ask Sam Clinical Co-pilot offers a comprehensive suite of features designed to simplify your workflows. You can create and manage detailed Clinical Notes on your patients efficiently, receive alerts on drug-drug interactions and recommended medications and lab tests, see AI-driven data visualization that helps you better understand trends in your patients' health history, and make use of Ask Sam, our AI assistant, who you can ask questions about the patient's medical information.",
    },
    {
      question: "Can my patients use the Ask Sam app?",
      answer:
        "Yes, the Ask Sam app is designed for both clinicians and patients. Your patients can download and use the app to book appointments, communicate with healthcare providers, share and manage their health records, conduct self-assessments, and receive health-related guidance and support. For more details on how patients can benefit from the app, please visit our website or contact our support team.",
    },
  ],
  using_the_platform: [
    {
      question: "How do I create and populate a Clinical Note?",
      answer:
        "First, navigate to 'Home' from the left-hand menu and click on the 'Create Clinical Note' button. Next, select a patient to create a note on, or create a new patient. Your Clinical Note has now been created. To populate it, click on the microphone icon with the 'Generate Note via Voice/Upload' pop-up on the right side of the screen. From here, you can upload a medical document by clicking on the 'Upload' button or create a voice input by clicking on the microphone button and talking. A transcript of your document/voice input will be shown on the screen and can be edited. Click 'Send Transcription' to add this information to your note.",
    },
    {
      question: "Can I edit the Clinical Note after it has been populated?",
      answer:
        "Yes, you can freely edit all information in the note at any point until you submit it.",
    },
    {
      question: "How do I navigate the sections in a Clinical Note?",
      answer:
        "To navigate a Clinical Note, use the tabs at the top of the main panel. 'Clinical Advice' lets you review information about the session and make recommendations. 'Clinical Examination' describes all the medical tests taken by the patient. 'Follow-Up Note' charts the patient's progress, and 'Case History' details their medical history.",
    },
    {
      question: "How do I add a patient?",
      answer:
        "To add a new patient profile, navigate to 'Home' from the left-hand menu, then go to 'Create Clinical Note'. Then, click on the 'Create New Patient Profile' button. On the next screen, fill in the patient's details, and click on 'Confirm and Create Clinical Note'. Your patient has now been added, and a clinical note has been created for them.",
    },
   
    {
      question:
        "How can I prescribe medications using Ask Sam Clinical Co-Pilot?",
      answer:
        "To prescribe medications, select 'Prescribe Medication' on the left-hand menu. This will redirect you to MediRecords, where you can prescribe.",
    },
    {
      question:
        "How do I submit my TGA report using Ask Sam Clinical Co-Pilot?",
      answer:
        "To fill out your TGA report, select 'TGA Report' on the left-hand menu.",
    },
    {
      question: "How can I see my appointments?",
      answer:
        "To see and manage your appointments, select 'Appointment Dashboard' on the left-hand menu.",
    },
  ],
  "co-Pilot_features": [
     {
      question: "What is 'Graph' and how do I use it?",
      answer:
        "'Graph' is a visualization tool within Clinical Co-pilot that breaks down complex patient data as intuitive, interactive diagrams based on decades of medical research. To use it, go to a Clinical Note, click on 'Assistant' and navigate to the 'Graph' tab.",
    },
    {
      question: "How does 'Ask Stella' work?",
      answer:
        "'Ask Stella' is an AI-powered assistant integrated into Ask Sam Clinical Co-pilot, designed to provide instant responses to your clinical queries. To use it, go to a Clinical Note, click on 'Assistant' and navigate to the 'Ask Stella' tab.",
    },
    {
      question: "What are 'Alerts' and how can they help me?",
      answer:
        "'Alerts' are notifications about possible drug-drug interactions and comorbidities, or suggestions about lab tests and medications. To see them, go to a Clinical Note and click on the warning icon with the 'Alerts' pop-up on the right side.",
    },
    {
      question:
        "What do I do if the auto-populated information in the Clinical Note is incorrect?",
      answer:
        "If a field in the Clinical Note does not accurately reflect the information in your uploaded document or voice input, you can click on the 'regenerate' icon below it to refresh it, or click on 'dislike' to indicate to us that the response is incorrect. You may also manually edit the field for accuracy.",
    },
  ],

  customer_support_and_feedback: [
    {
      question: "What should I do if I encounter a technical issue?",
      answer:
        "If you experience a technical issue with Clinical Co-pilot, please report the issue to us by raising a ticket. Go to 'Help Center' from the left-hand menu and click on the 'Contact Support' button to start a ticket. Describe your problem in detail here, and submit the ticket. Our support team will reach out to you shortly.",
    },
    {
      question: "Who do I contact if I have trouble logging in?",
      answer:
        "If you have trouble logging in, you can email us directly at contactus@AskSam.com.au with a detailed description of your issue.",
    },
    {
      question: "How do I submit feedback?",
      answer:
        "To submit feedback, first navigate to Help Center from the left-hand menu and click on the 'Contact Support' button. Choose 'I want to submit feedback' as the subject for your ticket, and describe any changes you would like to see. We value your input and work constantly to make our product better for our users.",
    },
  ],
  security_and_accessibility: [
    {
      question: "What security measures are in place to protect my data?",
      answer:
        "Ask Sam Clinical Co-pilot prioritises the security and confidentiality of your data with robust measures in place. We employ industry-standard encryption protocols to secure data both in transit and at rest, ensuring that all your information is protected against unauthorized access. Additionally, we implement strict access controls, regularly audit our systems, and comply with healthcare regulations like HIPAA to safeguard your privacy. Regular security updates and vulnerability assessments are also conducted to maintain the integrity and resilience of our systems.",
    },
    {
      question:
        "How does Ask Sam Clinical Co-pilot comply with healthcare regulations?",
      answer:
        "Ask Sam Clinical Co-pilot adheres strictly to all healthcare regulations to ensure the privacy and security of patient information. All patient data is encrypted both in transit and at rest, using advanced encryption standards. We also implement strong access controls and authentication measures to ensure that only authorized personnel can access sensitive information. Comprehensive logging and audit trails are maintained to monitor and review access and usage of patient data, and regular risk assessments are conducted to identify and mitigate potential security vulnerabilities. Finally, all staff undergo regular training on compliance and our privacy policy. These measures help us maintain the highest standards of data protection and compliance with healthcare laws, thereby safeguarding the confidentiality, integrity, and availability of patient information.",
    },
    {
      question: "Who has access to my data?",
      answer:
        "Access to your data is strictly controlled and limited to authorised individuals. This includes yourself, people you explicitly grant access, and a limited number of support and technical staff that provide system maintenance and support. Auditors may also access data for compliance reviews, but this is conducted under stringent regulatory oversight and is strictly for ensuring adherence to healthcare regulations. Every access point is logged and monitored to ensure that it complies with our strict privacy and security protocols, safeguarding your information against unauthorized use.",
    },
    {
      question: "How often is Ask Sam Clinical Co-pilot updated?",
      answer:
        "Ask Sam Clinical Co-pilot is updated regularly to ensure optimal performance, security, and functionality. Updates include new features, enhancements, bug fixes, performance improvements, and critical security patches. Routine maintenance updates are also performed to keep the system running smoothly and efficiently.",
    },
    {
      question:
        "Can Ask Sam Clinical Co-pilot integrate with other medical software systems?",
      answer:
        "Yes, Ask Sam Clinical Co-pilot is designed to seamlessly integrate with a variety of medical software systems. For specific integration requirements or to understand how Clinical Co-pilot can be integrated into your existing infrastructure, please contact our support team.",
    },
  ],
  credits: [
    {
      question: "What is the Credit Management System?",
      answer:
        "The Credit Management System is a feature that allocates and tracks credits for clinicians. These credits are used to access various functionalities within Ask Sam Clinical Co-pilot, such as creating and managing clinical notes. The system helps clinicians manage their usage efficiently by providing real-time updates on their credit balance, detailed usage history, and notifications for low balance or credit expiration. Admins can also manage credit policies, allocate additional credits, and adjust credit parameters as needed.",
    },
    {
      question: "How do I receive my initial credits?",
      answer:
        "Upon successful registration and email verification, each clinician is automatically allocated 300 credits. These credits are valid for a period of 3 months from the date of allocation and can be used to access various functionalities within Ask Sam Clinical Co-pilot, such as creating and managing clinical notes. You can view your initial credit balance and usage details on your account dashboard. Please note that credit amounts and timeframes are subject to change as per company policy.",
    },
    {
      question: "How is my credit balance updated?",
      answer:
        "Your credit balance is updated in real time. Each time you create a Clinical Note or use a feature that requires credits, the corresponding number of credits is deducted from your balance immediately. You can view your current credit balance and detailed usage history on your account dashboard, allowing you to keep track of your remaining credits and how they have been utilized.",
    },
    {
      question: "What happens if I run out of credits?",
      answer:
        "If you run out of credits, you will not be able to create new clinical notes or access other functionalities that require credits. To continue using these features, you will need to obtain additional credits. You can contact our support team to request more credits or to inquire about purchasing additional credits. Admins also have the ability to allocate additional credits based on usage or special promotions.",
    },
    {
      question: "Can my unused credits roll over to the next period?",
      answer:
        "Usually, unused credits do not roll over to the next period and expire at the end of their validity. However, the rollover policy is at the discretion of the company and may change. Please refer to the latest terms and conditions or contact support for the most up-to-date information.",
    },
    {
      question: "How do I receive additional credits?",
      answer:
        "You can receive additional credits through various means. Admins have the ability to allocate extra credits based on usage, promotions, or special requests. If you need more credits, you can contact our support team to request additional credits or to inquire about purchasing more. You will be notified via email when additional credits are added to your account.",
    },
    {
      question: "How can I track my credit usage?",
      answer:
        "You can track your credit usage through the 'Credits' section under 'Settings'. This section provides a detailed history of your credit transactions, including the dates, amounts, and the remaining balance after each transaction.",
    },
    {
      question: "Who should I contact for support regarding credits?",
      answer:
        "If you have any questions or issues related to your credits, please contact our support team. You can reach us via email at contactus@asksam.com.au. Our team is available 24/7 to assist you with any credit-related inquiries or concerns.",
    },
  ],
};

function HelpCenterFAQs() {
  const navigate = useNavigate();
  const { domain } = useContext(AppContext);

  return (
    <div className="mt-3 ExpertHelpCenter--Dashboard">
      <div className="ExpertHelpCenter--Header d-flex justify-content-between align-items-center">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => {
                navigate("/clinical/help-center");
              }}
              role="button"
              color="text.primary"
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
              }}
              className="d-flex text-secondary align-items-center justify-content-between gap-2 "
            >
              Help Center
            </Link>

            <Typography
              sx={{ fontWeight: "500", fontSize: "20px" }}
              color="text.primary"
            >
              FAQs
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="d-none">
        <Typography
          sx={{ fontWeight: "500", fontSize: "24px" }}
          className="mt-3"
          color="text.primary"
        >
          FAQs
        </Typography>
        <p style={{ fontSize: "1rem" }} className="mt-3 mb-5">
          Get quick answers to common questions about our products, services,
          and policies.
        </p>
      </div>

      <div className="mt-5" style={{ maxWidth: "1440px", margin: "auto" }}>
        {isAusDomain()
          ? Object.keys(ausFaqData).map((item, idx) => (
              <div key={idx}>
                <h6 className="m-0 fw-bold fs-4 my-4">
                  {item.charAt(0).toUpperCase() +
                    item.slice(1).replace(/_/g, " ")}
                </h6>
                {ausFaqData[item]?.map((key, index) => (
                  <div key={index} className="my-1">
                    <Accordion
                      data-help_center-faq={key.question}
                      className="help_center_faq"
                      key={index}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography className=" fw-semibold">
                          {key.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="  fw-normal">
                          {key.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            ))
          : Object.keys(faqData).map((item, idx) => (
              <div key={idx}>
                <h6 className="m-0 fw-bold fs-4 my-4">
                  {item.charAt(0).toUpperCase() +
                    item.slice(1).replace(/_/g, " ")}
                </h6>
                {faqData[item]?.map((key, index) => (
                  <div key={index} className="my-1">
                    <Accordion
                      data-help_center-faq={key.question}
                      className="help_center_faq"
                      key={index}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography className=" fw-semibold">
                          {key.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className="  fw-normal">
                          {key.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </div>
            ))}
      </div>
    </div>
  );
}

export default HelpCenterFAQs;
