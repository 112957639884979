import { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DashboardHeader from "./component/DashboardHeader";

//Icons
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import LockIcon from "@mui/icons-material/Lock";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import ClinicalDashboardHeader from "./component/ClinicalDashboardHeader";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  Checkbox,
} from "@mui/material";
import { AppContext } from "../../context";
import { useAuth } from "../../auth";
import {
  Assessment,
  AssessmentOutlined,
  CallOutlined,
  CheckCircleOutline,
  Close,
  ReportOff,
  SpaceDashboardOutlined,
  SummarizeOutlined,
  SummarizeRounded,
  VaccinesOutlined,
  WarningAmber,
  WarningAmberRounded,
} from "@mui/icons-material";
import {
  acceptNextSteps,
  acceptTNC,
  fetchCountries,
  fetchExpertDetails,
  fetchStates,
  getDisclaimer,
  getNextSteps,
  getTNC,
  sendNotification,
  validate_token,
} from "../core/_request";
import { useSnackbar } from "notistack";
import CautionContent from "./component/CautionContent";
import { onboardingStatus } from "./clinicalNotesClinicalRoute/ClinicalNotesAPI/ClinicalNoteAPIManager";
import SettingUpDialog from "./clinicalNotesClinicalRoute/Dialogs/SettingUpDialog";
import dayjs from "dayjs";
import DisclaimerModal from "./modals/DisclaimerModal";
import AcceptCCOPDisclaimer from "./modals/AcceptCCOPDisclaimer";
import { getSubDomain, isAusDomain } from "./clinicalNotesClinicalRoute/lib/utils";

const iconMap = {
  Dashboard: DashboardOutlinedIcon,
  Appointments: EventAvailableOutlinedIcon,
  "Session Management": EventAvailableIcon,
  "Clinical Co-Pilot": NoteAltOutlinedIcon,
  Clients: GroupOutlinedIcon,
  Patients: GroupOutlinedIcon,
  Chat: ForumOutlinedIcon,
  "My Schedule": CalendarMonthOutlinedIcon,
  Worksheets: EventAvailableOutlinedIcon, // Assuming a generic icon for demonstration
  Articles: EventAvailableOutlinedIcon, // Assuming a generic icon for demonstration
  Posts: SettingsOutlinedIcon,
  Notifications: NotificationsOutlinedIcon,
  Communities: HeadsetMicOutlinedIcon,
  "Help Center": HeadsetMicOutlinedIcon,
  Payouts: PaymentOutlinedIcon,
  Settings: ManageAccountsOutlinedIcon,
  Home: DashboardOutlinedIcon,
  "Talk to Us": CallOutlined,
  "TGA Report": SummarizeOutlined,
  "Appointment Dashboard": SpaceDashboardOutlined,
};

const clinicalMenu = [
  { name: "Home", imageLink: "/assets/img/", link: "home" },
  { name: "Clients", imageLink: "/assets/img/", link: "clients" },
  { name: "Patients", imageLink: "/assets/img/", link: "patients" },
  { name: "TGA Report", imageLink: "/assets/img/", link: "coming-soon" },
  { name: "Appointment Dashboard", imageLink: "/assets/img/", link: "expert-dashboard" },
  { name: "Help Center", imageLink: "/assets/img/clinical_notes_assets/helpcenter.png", link: "help-center" },
  { name: "Talk to Us", imageLink: "/assets/img/", link: "talk-to-us" },
  { name: "Settings", imageLink: "/assets/img/", link: "settings" },
];
export const ClinicalDashboard = () => {
  const [isActive, setIsActive] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const currentUrl = location.pathname.split("?")[0];
  const { credits, expertDetails, setExpertDetails } = useContext(AppContext);
  const [showWarning, setShowWarning] = useState(true);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [tncDialog, setTncDialog] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [disclaimercontent, setDisclaimercontent] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [openNextStep, setOpenNextStep] = useState(false);
  const userRole = `${Cookies.get("authUserRole")}`
  useEffect(() => {
    let filteredData = clinicalMenu.filter((item) =>
      currentUrl?.includes(item.link)
    );
    setIsActive(filteredData[0]?.name);
    // HandleDisclaimerFromAPI();
    // checkNextSteps();
  }, [currentUrl]);

  const onClickMenu = (name) => {
    scrollChatToTop()
    if (name === "Talk to Us") {
      if (isAusDomain()) {
        window.open("https://cal.com/contact-ask-sam/30min?date=2024-11-20&month=2024-11")
      } else {
        window.open("https://calendar.app.google/xFDTideqm8ai3PxA6")
      }

      return
    }
    if (name === "Appointment Dashboard") {

      if (isAusDomain()) {
        // To be updated with env variables
        window.open(`https://dashboard${getSubDomain()}/`)
      } else if (currentUser?.Country === "India") {
        window.open("https://my.unitedwecare.com/expert/dashboard")
      } else {
        window.open("https://us.unitedwecare.com/expert/dashboard")
      }
      return
    }
    setIsActive(name);
  };

  // Fetch User details
  useEffect(() => {
    if (!currentUser) return;

    const payload = {
      user_id: currentUser?.UserId,
      email: currentUser?.Emailid,
      screen: null,
    };

    const loadExpertDetails = async () => {
      try {
        const data = await fetchExpertDetails(payload);
        setExpertDetails(data);
      } catch (error) {
        console.error("Failed to fetch expert details:", error);
      }
    };

    loadExpertDetails();
  }, []);

  // Fetch user Country
  useEffect(() => {
    if (!expertDetails?.profile?.country_id) return;

    const loadCountries = async () => {
      try {
        const countries = await fetchCountries();
        const countryName = countries?.find(
          (item) => item.id === expertDetails?.profile?.country_id
        )?.name;

        setExpertDetails((prevDetails) => ({
          ...prevDetails,
          profile: {
            ...prevDetails?.profile,
            country: countryName,
          },
        }));
        setCountryList(countries);
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      }
    };

    loadCountries();
  }, [expertDetails?.profile?.country_id]);

  // Fetch user state
  useEffect(() => {
    if (countryList.length === 0) return;

    const loadStates = async () => {
      try {
        const countryItem = countryList?.find(
          (item) => item.id === expertDetails?.profile?.country_id
        );
        const countryCode = countryItem?.code;

        const states = await fetchStates(countryCode);
        const stateName = states?.find(
          (item) => item.id === expertDetails?.profile?.state_id
        )?.name;

        setExpertDetails((prevDetails) => ({
          ...prevDetails,
          profile: {
            ...prevDetails?.profile,
            state: stateName,
          },
        }));
      } catch (error) {
        console.error("Failed to fetch states:", error);
      }
    };

    loadStates();
  }, [expertDetails?.profile?.country]);

  // Get disclaimer content from API
  // const HandleDisclaimerFromAPI = async () => {
  //   const response = await getDisclaimer();
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(response, "text/html");
  //   const contentDiv = doc.querySelector(".theme-doc-markdown.markdown");
  //   const encryptedEmails = contentDiv.querySelectorAll(".__cf_email__");
  //   encryptedEmails.forEach((element) => {
  //     element.innerHTML =
  //       `<a  href="mailto:${process.env.REACT_APP_CLIENT_EMAIL}">${process.env.REACT_APP_CLIENT_EMAIL}</a>`;
  //   });
  //   if (contentDiv) {
  //     setDisclaimercontent(contentDiv.innerHTML);
  //   } else {
  //     const fallbackMessage = `
  //                       Disclaimer. 
  //                       <a href="https://legal${getSubDomain()}/disclaimer-ccop" target="_blank">Read more</a>`;
  //     document.getElementById("disclaimer-content").innerText = fallbackMessage;
  //     setDisclaimercontent(fallbackMessage);
  //   }
  // };
  // Check if user accepted the caution
  // const checkDisclaimer = () => {
  //   const disclaimerStatus = JSON.parse(
  //     window.localStorage.getItem("disclaimer")
  //   )?.status;
  //   if (!disclaimerStatus) {
  //     // If user not accepted caution show caution dialog
  //     setDisclaimer(true);
  //   } else {
  //     // If user accepted caution check onboarding status
  //     // getOnboardingStatus();
  //     setLockScreen(false);
  //   }
  // };

  // If user accepted Disclaimer 
  // const checkTnc = async () => {
  //   const tnc = await getTNC(currentUser?.UserId);
  //   if (tnc?.success) {
  //     if (tnc?.status) {
  //       // If user accepted Disclaimer check caution
  //       checkDisclaimer();
  //     } else {
  //       // If user not accepted Disclaimer show Disclaimer dialog
  //       window.localStorage.removeItem("disclaimer");
  //       setTncDialog(true);
  //     }
  //     // fetchNotification()
  //   } else {
  //     enqueueSnackbar("Error fetching disclaimer status.", {
  //       variant: "error",
  //     });
  //   }
  // };

  // const fetchNotification = async () => {
  //   try {
  //     const notificationBody = {
  //       event: "ccop_accept_terms",
  //       event_metadata: {
  //         timestamp: dayjs().unix(),
  //       },
  //       user_id: `${currentUser?.UserId}`,
  //     };
  //     sendNotification(JSON.stringify(notificationBody));
  //   } catch (error) { }
  // };

  // // Accept Disclaimer
  // const acceptTnc = async () => {
  //   const tnc = await acceptTNC(currentUser?.UserId);
  //   if (tnc?.success) {
  //     setTncDialog(false);
  //     checkTnc();
  //   } else {
  //     enqueueSnackbar("Error accepting T&C.", { variant: "error" });
  //   }
  // };

  // // Check for user onboarding status
  // const getOnboardingStatus = async () => {
  //   const response = await onboardingStatus(currentUser?.UserId);
  //   if (response.ResponseCode === 200) {
  //     if (!response.status) {
  //       //console.log(window.location.origin);
  //       window.location.href = `https://myaccount${getSubDomain()}/onboarding/home?redirect=${window.location.origin}/auth`;
  //     } else {
  //       checkTnc()
  //       // checkNextSteps();
  //     }
  //   } else {
  //     enqueueSnackbar(response?.ResponseMessage, { variant: "error" });
  //   }
  // };

  // // Check for user next steps
  // const checkNextSteps = async () => {
  //   const response = await getNextSteps(currentUser?.UserId);
  //   if (response.ResponseCode === 200) {
  //     if (!response.status) {
  //       // If user dont completed next steps, show next steps dialog
  //       setOpenNextStep(true);
  //     } else {
  //       checkTnc()
  //     }
  //   } else {
  //     enqueueSnackbar(response?.ResponseMessage, { variant: "error" });
  //   }
  // };

  // // Accept next steps
  // const handleNextSteps = async () => {
  //   const acceptStep = await acceptNextSteps(currentUser?.UserId);
  //   if (acceptStep.ResponseCode === 200) {
  //     setOpenNextStep(false);
  //     checkTnc()
  //   } else {
  //     enqueueSnackbar(acceptStep?.ResponseMessage, { variant: "error" });
  //   }
  // };

  const profileImage = currentUser?.ProfileImage?.replace(
    "https://app.uwc.worldhttps://",
    "https://"
  );
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate("/clinical/settings");

    handleDropdownClose();
  };

  const handleLogoutClick = () => {
    // Perform logout actions here
    logout();
    if (isAusDomain()) {
      window.location.href = `https://www${getSubDomain()}`;
    } else {
      window.location.href = `https://www${getSubDomain()}/clinical-co-pilot/`;
    }
    handleDropdownClose();
  };


  const scrollRef = useRef(null);
  const scrollChatToTop = () => {
    console.log("scrolling to top");

    const chatElement = scrollRef.current;
    if (chatElement) {
      // Use setTimeout to allow DOM updates to complete before scrolling
      setTimeout(() => {
        chatElement.scrollTop = 0;
      }, 0);
    }
  };

  return (
    <>
      <Helmet>
        <title>Clinical Copilot</title>
      </Helmet>
      <ClinicalDashboardHeader
        handleHamburger={() => setHamburgerOpen(!hamburgerOpen)}
      />

      {/* {showWarning && <div style={{ background: "#FDB022" }} className=" d-flex justify-content-between px-3 py-2 align-items-md-center">
        <div>

        </div>
        <div className="d-flex flex-column gap-2 flex-md-row">
          <WarningAmber />  Verify your email adress to guarantee the best email and calendar deliverability.
          <span role="button" className=" text-decoration-underline">Resend email</span>
        </div>
        <div className="">
          <Close role="button" onClick={() => setShowWarning(false)} />
        </div>
      </div>} */}
      <Container
        fluid
        style={{ background: "#F5F5F5" }}
        className="ExpertDashboard "
      >
        {lockScreen && (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              zIndex: "1000",
              background: "#5a5a5a7d",
              top: 0,
              left: 0,
            }}
            className=" position-fixed d-flex justify-center items-center"
          ></div>
        )}
        <Row className="d-flex flex-column flex-lg-row">
          {hamburgerOpen && (
            <Col
              style={{ borderBottom: "1px solid #D8D8D8", width: "100%" }}
              className="p-0 fixed-top h-100  d-lg-none d-flex col-lg-3 col-xl-2 "
            >
              <div
                style={{ background: "var(--primary-color)", width: "85%" }}
                className="px-3 pt-3"
              >
                <Nav className="d-flex justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <Button onClick={handleDropdownOpen}>
                      <img
                        src={profileImage}
                        width={60}
                        height={60}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                      />
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleDropdownClose}
                    >
                      <MenuItem
                        id="view_profile"
                        onClick={handleProfileClick}
                        sx={{ width: "200px" }}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        id="logout"
                        onClick={handleLogoutClick}
                        sx={{ width: "200px" }}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                    <span
                      id="credits"
                      role="button"
                      onClick={() => {
                        navigate("/clinical/settings?view=Credits", {
                          state: { view: "Credits" },
                        });
                      }}
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        background: "#FFFAEB",
                        color: "#F79009",
                        border: "2px solid #F79009",
                      }}
                      className="d-flex  px-2 py-1   gap-2 align-items-center  rounded-pill"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="lucide lucide-badge-cent"
                      >
                        <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                        <path d="M12 7v10" />
                        <path d="M15.4 10a4 4 0 1 0 0 4" />
                      </svg>{" "}
                      {credits}
                    </span>
                  </div>
                  <div>
                    <IconButton
                      id="close_hamburger"
                      onClick={() => setHamburgerOpen(!hamburgerOpen)}
                    >
                      <Close sx={{ fontSize: "30px", color: "white" }} />
                    </IconButton>
                  </div>
                </Nav>
                <Nav className="flex-column gap-1 my-3 NavigationRail">
                  {clinicalMenu?.filter((item) => {
                    const currentHost = window.location.host;
                    const isAskSam = isAusDomain();
                    const isExpert = userRole.includes("expert");

                    if (isAskSam && isExpert) return !["Clients"].includes(item.name);
                    if (isAskSam) return !["Clients", "Appointment Dashboard"].includes(item.name);
                    if (isExpert) return !["TGA Report", "Patients"].includes(item.name);

                    return !["TGA Report", "Appointment Dashboard", "Patients"].includes(item.name);
                  }).map((menu) => {
                    const IconComponent = iconMap[menu.name]; // Dynamically get the icon component
                    return (
                      <Nav.Link
                        key={menu.name}
                        as={Link}
                        to={menu.link}
                        onClick={() => {
                          onClickMenu(menu.name);
                          setHamburgerOpen(!hamburgerOpen);
                        }}
                        className="d-flex align-items-center gap-6"
                        style={{
                          background: isActive === menu.name ? "white" : "",
                          borderRadius: "10px",
                          fontSize: "14px",
                          color: isActive === menu.name ? "black" : "white",
                        }}
                      >
                        <IconComponent
                          sx={{
                            color: isActive === menu.name ? "#000" : "#FFF",
                          }}
                        />
                        <p className="font-inter m-0">{menu.name}</p>
                      </Nav.Link>
                    );
                  })}
                </Nav>
              </div>
              <div
                onClick={() => setHamburgerOpen(!hamburgerOpen)}
                style={{ background: "#79797980" }}
                className="col"
              ></div>
            </Col>
          )}
          {
            <Col
              style={{ borderRight: "1px solid #D8D8D8" }}
              className="px-3 h-100 d-lg-block d-none col-lg-3 col-xl-2 "
            >
              <Nav className=""></Nav>
              <Nav className="flex-column gap-1 my-3 NavigationRail">
                {clinicalMenu?.filter((item) => {
                  const currentHost = window.location.host;
                  const isAskSam =isAusDomain();
                  const isExpert = userRole.includes("expert");

                  if (isAskSam && isExpert) return !["Clients"].includes(item.name);
                  if (isAskSam) return !["Clients", "Appointment Dashboard"].includes(item.name);
                  if (isExpert) return !["TGA Report", "Patients"].includes(item.name);
                  return !["TGA Report", "Appointment Dashboard", "Patients"].includes(item.name);
                }).map((menu) => {
                  const IconComponent = iconMap[menu.name]; // Dynamically get the icon component
                  return (
                    <Nav.Link
                      key={menu.name}
                      as={Link}
                      id={`${menu.link}_tab`}
                      to={menu.link === "talk-to-us" ? "home" : menu.link}
                      onClick={() => onClickMenu(menu.name)}
                      className="d-flex p-3 fw-bold align-items-center gap-6"
                      style={{
                        background:
                          isActive === menu.name ? "var(--primary-accent-color)" : "",
                        borderRadius: "10px",
                        fontSize: "14px",
                        color: isActive === menu.name ? "var(--primary-color)" : "#101828",
                      }}
                    >
                      <IconComponent
                        sx={{ color: isActive === menu.name ? "var(--primary-color)" : "##101828" }}
                      />

                      <p className="font-inter m-0">{menu.name}</p>
                    </Nav.Link>
                  );
                })}
              </Nav>
            </Col>
          }

          {/* Content */}
          <Col
            style={{ background: "#fff" }}
            className="pt-3 ExpertDashboard--Col"
            ref={scrollRef}
          >
            <Outlet />
          </Col>
        </Row>
      </Container>


      {/* Hidden Modal */}

      {/* <DisclaimerModal
        disclaimer={disclaimer}
        close={() => setDisclaimer(true)}
        handleAccept={() => {
          window.localStorage.setItem(
            "disclaimer",
            JSON.stringify({ status: true })
          );
          setDisclaimer(false);
          // getOnboardingStatus();
          setLockScreen(false);
        }}
      />
      <AcceptCCOPDisclaimer
        open={tncDialog}
        close={() => setTncDialog(true)}
        disclaimercontent={disclaimercontent}
        handleAccept={() => {
          fetchNotification();
          acceptTnc();
        }}
      />


      <SettingUpDialog
        open={openNextStep}
        finish={() => {
          handleNextSteps();
        }}
        close={() => setOpenNextStep(true)}
      /> */}
    </>
  );
};
