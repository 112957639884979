import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NoDataSvg } from "../../../clinicalNotesClinicalRoute/ClinicalNoteSVGs";

function Courses({ clientDetails }) {
  const [loading, setLoading] = useState(false);
  const [clientCourses, setClientCourses] = useState([]);

  //Fetch Client Courses

  useEffect(() => {
    if (!clientDetails?.id) {
      // If checkClientID is blank, return early
      return;
    }
    const payload = {
      userId: clientDetails?.id,
    };
    setLoading(true);
    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/community/course/active_courses`,
          {
            method: "POST",
            headers: {
              "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { courses } = await postResponse.data;

        setClientCourses(courses);
        //setUserData(postResponse?.data);
        setLoading(false);
        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();
  }, [clientDetails?.id]);
  return (
    <div>
      {" "}
      {loading ? (
        Array.from(new Array(10)).map((_, index) => (
          <>
            <div
              className="d-flex flex-row gap-2 align-items-center"
              style={{
                // backgroundColor: "rgb(0,0,0,0.09)",
                borderRadius: "10px",
                padding: "5px",
              }}
            >
              <Skeleton width={122} height={69} sx={{ borderRadius: "10px" }} />

              <div className="d-flex flex-column gap-2">
                <Skeleton
                  variant="rounded"
                  width="15vw"
                  height={20}
                  sx={{ borderRadius: "5px" }}
                />
                <Skeleton
                  variant="rounded"
                  width="10vw"
                  height={20}
                  sx={{ borderRadius: "5px" }}
                />
              </div>
            </div>
            <hr />
          </>
        ))
      ) : clientCourses?.length > 0 ? (
        <Table className="mt-4">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {clientCourses?.map((item) => (
              <TableRow className="Course--Item">
                {/* Course Image */}
                <TableCell>
                  <Box
                    component="img"
                    src={item?.website_thumbnail_url}
                    width={122}
                    height={69}
                    alt={item?.name}
                    loading="lazy"
                    className=" rounded-3"
                    sx={{ backgroundColor: "rgb(0,0,0,0.07)" }}
                  />
                </TableCell>

                {/* Course Title */}
                <TableCell>
                  <Typography variant="body2">{item?.name}</Typography>
                </TableCell>

                {/* Course Status */}
                <TableCell>
                  <Typography
                    variant="body2"
                    className={item?.status?.toLowerCase()}
                    sx={{
                      color:
                        item?.status?.toLowerCase() === "completed"
                          ? "green"
                          : item?.status?.toLowerCase() === "in progress"
                          ? "orange"
                          : "red",
                    }}
                  >
                    {item?.status}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="NoDataAvailable">
          {NoDataSvg}
          <p> No Courses Available</p>
        </div>
      )}
    </div>
  );
}

export default Courses;
