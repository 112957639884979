import React, { useContext } from "react";
import { AppContext } from "../../../../context";
import dayjs from "dayjs";

// Component
import TextEditor from "./TextEditor";

// MUI
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import {
  AccessTimeOutlined,
  DoNotDisturbOnOutlined,
} from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { isAusDomain } from "../lib/utils";

function RecommendedContentListView({ download }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const handleRemoveContent = (indexToRemove) => {
    setExpertClinicalNotesData((prev) => {
      return {
        ...prev,

        recommendation: {
          ...prev?.recommendation,
          recommended_content:
            prev?.recommendation?.recommended_content?.filter(
              (item, i) => i !== indexToRemove
            ),
        },
      };
    });
  };
  return (
    <div>
      {" "}
      {expertClinicalNotesData?.recommendation?.recommended_content?.length >
        0 && (
        <div>
          <Grid
            container
            spacing={3}
            className="pb-5 pe-lg-4 mt-2"
            sx={{
              backgroundColor: "#FFF",
              marginLeft: 0,
              marginRight: 0,

              width: "100%",
            }}
          >
            {expertClinicalNotesData?.recommendation?.recommended_content?.map(
              (data, index) => (
                <Grid
                  item
                  key={index}
                  className="p-0 mt-2 ps-lg-4"
                  xs={12}
                  lg={download ? 12 : 6}
                >
                  <div
                    style={{ background: "#F8F8F8" }}
                    className="p-3 rounded-3"
                  >
                    <div className="d-flex  justify-content-between ">
                      <div className="d-flex">
                        <img
                          src={
                            data?.data?.smallThumbnailUrl ||
                            "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                          }
                          width={78}
                          height={78}
                          className="rounded cover rounded-3"
                          alt=""
                        />
                        <div className="px-2">
                          <p
                            style={{ fontSize: "14px" }}
                            className="fw-bold m-0"
                          >
                            {data?.data?.title
                              ? data.data.title.charAt(0).toUpperCase() +
                                data.data.title.slice(1)
                              : ""}
                          </p>

                          <p
                            style={{ fontSize: "14px" }}
                            className=" d-flex align-items-center m-0 gap-1"
                          >
                            {" "}
                            {data?.type
                              ? data.type.charAt(0).toUpperCase() +
                                data.type.slice(1)
                              : ""}{" "}
                            <AccessTimeOutlined sx={{ fontSize: "16px" }} />
                            {data?.data?.duration}
                          </p>
                          <p
                            style={{ fontSize: "14px" }}
                            className=" d-flex align-items-center m-0 gap-1"
                          >
                            {" "}
                            # {data?.data?.category?.name}
                          </p>
                        </div>
                      </div>

                      {!expertClinicalNotesData?.data?.submitted &&
                        !download && (
                          <div
                            role="button"
                            onClick={() => handleRemoveContent(index)}
                          >
                            <DoNotDisturbOnOutlined
                              sx={{ color: "var(--primary-color)" }}
                            />
                          </div>
                        )}
                    </div>
                    <p
                      style={{ fontSize: "16px" }}
                      className="fw-bold m-0 mt-3"
                    >
                      Reminder
                    </p>
                    <div className="d-flex date--picker flex-column flex-md-row justify-content-lg-around gap-2 gap-lg-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="col d-flex flex-column">
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#6E6E6E",
                            }}
                            className="mb-2"
                          >
                            Start Date
                          </p>
                          {/* <DatePicker
                            sx={{ background: "white" }}
                            // defaultValue={today}
                            value={dayjs(data?.start_date)}
                            views={["year", "month", "day"]}
                            disabled={expertClinicalNotesData?.data?.submitted}
                            onChange={(e) => {
                              setExpertClinicalNotesData((prev) => {
                                return {
                                  ...prev,

                                  recommendation: {
                                    ...prev?.recommendation,
                                    recommended_content:
                                      prev?.recommendation?.recommended_content?.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              start_date: new Date(
                                                e
                                              ).toISOString(),
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                  },
                                };
                              });
                            }}
                          /> */}
                          <input
                            min={dayjs().format("YYYY-MM-DD")}
                            disabled={
                              expertClinicalNotesData?.data?.submitted ||
                              download
                            }
                            type="date"
                            className="w-100 rounded-3 border p-2 border-1 border-secondary"
                            value={dayjs(data?.start_date).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setExpertClinicalNotesData((prev) => {
                                return {
                                  ...prev,
                                  recommendation: {
                                    ...prev?.recommendation,
                                    recommended_content:
                                      prev?.recommendation?.recommended_content?.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              start_date: new Date(
                                                e.target.value
                                              ).toISOString(),
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="col  d-flex flex-column">
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#6E6E6E",
                            }}
                            className="mb-2"
                          >
                            End Date
                          </p>
                          {/* <DatePicker
                            sx={{ background: "white" }}
                            value={dayjs(data?.end_date)}
                            views={["year", "month", "day"]}
                            disabled={expertClinicalNotesData?.data?.submitted}
                            onChange={(e) => {
                              setExpertClinicalNotesData((prev) => {
                                return {
                                  ...prev,

                                  recommendation: {
                                    ...prev?.recommendation,
                                    recommended_content:
                                      prev?.recommendation?.recommended_content?.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              end_date: new Date(
                                                e
                                              ).toISOString(),
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                  },
                                };
                              });
                            }}
                          /> */}
                          <input
                            min={dayjs(data?.start_date).format("YYYY-MM-DD")}
                            type="date"
                            disabled={
                              expertClinicalNotesData?.data?.submitted ||
                              download
                            }
                            className="w-100 rounded-3 border p-2 border-1 border-secondary"
                            value={dayjs(data?.end_date).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setExpertClinicalNotesData((prev) => {
                                return {
                                  ...prev,
                                  recommendation: {
                                    ...prev?.recommendation,
                                    recommended_content:
                                      prev?.recommendation?.recommended_content?.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              end_date: new Date(
                                                e.target.value
                                              ).toISOString(),
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      </LocalizationProvider>
                    </div>
                    <div className="d-flex date--picker justify-content-around mt-2 mb-3 gap-4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="col d-flex flex-column">
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#6E6E6E",
                            }}
                            className="mb-2"
                          >
                            Time
                          </p>
                          <TimePicker
                            sx={{ background: "white" }}
                            value={dayjs(data?.time)}
                            disabled={
                              expertClinicalNotesData?.data?.submitted ||
                              download
                            }
                            onChange={(e) => {
                              setExpertClinicalNotesData((prev) => {
                                return {
                                  ...prev,

                                  recommendation: {
                                    ...prev?.recommendation,
                                    recommended_content:
                                      prev?.recommendation?.recommended_content?.map(
                                        (item, i) => {
                                          if (i === index) {
                                            return {
                                              ...item,
                                              time: new Date(e).toISOString(),
                                            };
                                          }
                                          return item;
                                        }
                                      ),
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="col d-flex flex-column">
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#6E6E6E",
                            }}
                            className="mb-2"
                          >
                            Frequency
                          </p>
                          <FormControl sx={{ background: "white" }} fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              value={
                                data?.frequency === "Once Daily"
                                  ? "Once_Daily"
                                  : data?.frequency === "Once Weekly"
                                  ? "Once_Weekly"
                                  : data?.frequency === "Once a Month"
                                  ? "Once_a_Month"
                                  : data?.frequency === "Twice Daily"
                                  ? "Twice_Daily"
                                  : "Frequency"
                              }
                              defaultValue={"Once_Daily"}
                              disabled={
                                expertClinicalNotesData?.data?.submitted ||
                                download
                              }
                              onChange={(e) => {
                                const frequency =
                                  e.target.value === "Once_Daily"
                                    ? "Once Daily"
                                    : e.target.value === "Once_Weekly"
                                    ? "Once Weekly"
                                    : e.target.value === "Once_a_Month"
                                    ? "Once a Month"
                                    : e.target.value === "Twice_Daily"
                                    ? "Twice Daily"
                                    : e.target.value;
                                setExpertClinicalNotesData((prev) => {
                                  return {
                                    ...prev,

                                    recommendation: {
                                      ...prev?.recommendation,
                                      recommended_content:
                                        prev?.recommendation?.recommended_content?.map(
                                          (item, i) => {
                                            if (i === index) {
                                              return {
                                                ...item,
                                                frequency: frequency,
                                              };
                                            }
                                            return item;
                                          }
                                        ),
                                    },
                                  };
                                });
                              }}
                            >
                              <MenuItem
                                value={"Frequency"}
                                disabled={
                                  expertClinicalNotesData?.data?.submitted ||
                                  download
                                }
                              >
                                Frequency
                              </MenuItem>
                              <MenuItem value={"Once_Daily"}>
                                Once Daily
                              </MenuItem>
                              <MenuItem value={"Twice_Daily"}>
                                Twice Daily
                              </MenuItem>
                              <MenuItem value={"Once_Weekly"}>
                                Once Weekly
                              </MenuItem>
                              <MenuItem value={"Once_a_Month"}>
                                Once a Month
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </LocalizationProvider>
                    </div>

                    <TextEditor
                      hideToolbar={true}
                      placeholder={
                        !download &&
                        `Add note to ${
                          isAusDomain()
                            ? "patient"
                            : "client"
                        }...`
                      }
                      initialText={data?.note_from_expert}
                      disabled={
                        expertClinicalNotesData?.data?.submitted || download
                      }
                      htmlText={(text) =>
                        setExpertClinicalNotesData((prev) => {
                          return {
                            ...prev,

                            recommendation: {
                              ...prev?.recommendation,
                              recommended_content:
                                prev?.recommendation?.recommended_content?.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        note_from_expert: text,
                                      };
                                    }
                                    return item;
                                  }
                                ),
                            },
                          };
                        })
                      }
                    />
                  </div>
                </Grid>
              )
            )}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default RecommendedContentListView;
