import React, { Component, useEffect, useState } from "react";
import AppRoutes from "./routing/AppRoutes";
import { AppContext } from "./context";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ThemeSwitcher from "./ThemeSwitcher";
import { isAusDomain } from "./components/expertDashboard/clinicalNotesClinicalRoute/lib/utils";
import GTMComponent from "./GTMComponent";



export default class App extends Component {
  render() {
    return <AppView />;
  }
}

export const getSubDomain = () => {
  const host = window.location.host;
  const domainPattern = /([a-zA-Z0-9-]+)\.(com|ai)$/; // Match domain like "unitedwecare.com" or "ask-sam.ai"

  const match = host.match(domainPattern);

  if (match) {
    return `${match[1]}.${match[2]}`; // Returns "unitedwecare.com" or "ask-sam.ai"
  }
  return `.${host}`;
};

const AppView = (props) => {
  const [ShowLloginPopup, setShowLloginPopup] = useState(0);
  const [expertDashboardData, setExpertDashboardData] = useState({});
  const [expertClinicalNotesData, setExpertClinicalNotesData] = useState({});
  const [CNrecommendationData, setCNrecommendationData] = useState({});
  const [credits, setCredits] = useState(0);
  const [expertDetails, setExpertDetails] = useState({});
  const [domain, setDomain] = useState(getSubDomain());
  useEffect(() => {
    const updateFavicon = (newFaviconUrl) => {
      const favicon = document.querySelector(
        "link[rel*='icon']"
      );

      if (favicon) {
        favicon.href = newFaviconUrl;

      } else {
        const newFavicon = document.createElement("link");
        newFavicon.rel = "icon";
        newFavicon.href = newFaviconUrl;
        document.head.appendChild(newFavicon);
      }
    };
   
    const faviconUrl = isAusDomain()
      ? "/faviconAskSam.ico" // AskSam favicon
      : "/favicon.ico";
    updateFavicon(faviconUrl);

  }, [])

  return (
    <SnackbarProvider maxSnack={3}
      action={(key) => (
        <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <AppContext.Provider
        value={{
          ShowLloginPopup,
          setShowLloginPopup,
          expertDashboardData,
          setExpertDashboardData,
          expertClinicalNotesData,
          setExpertClinicalNotesData,
          CNrecommendationData,
          setCNrecommendationData,
          credits,
          setCredits,
          expertDetails,
          setExpertDetails,
          domain
        }}
      >
        <ThemeSwitcher />
        <AppRoutes />
        <GTMComponent/>
      </AppContext.Provider>
    </SnackbarProvider>
  );
};
