import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useContext, useRef } from "react";
import { AppContext } from "../../../../context";
import { Close, Download } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import QRCode from "qrcode.react";
import { getSubDomain } from "../lib/utils";

function MerchantQr({ open, close }) {
  const { expertClinicalNotesData, expertDetails } = useContext(AppContext);
  const tab1Ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => tab1Ref.current,
    documentTitle: expertClinicalNotesData?.client?.name,
    onAfterPrint: () => {
      //   close();
    },
  });

  return (
    <Dialog
      open={open}
      onClose={() => close()}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          height: "100%",
          maxWidth: "600px",
          maxHeight: "90vh",
          margin: 0,
        },
      }}
      className=""
    >
      <DialogTitle className="p-2 p-sm-4">
        {" "}
        <div className="d-flex align-items-center justify-content-between gap-2 ">
          <IconButton
            id="download_qr_pdf"
            className="save "
            onClick={handlePrint}
          >
            <Download sx={{ color: "var(--primary-color)" }} />
          </IconButton>

          <IconButton onClick={() => close()}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="p-2 p-sm-4" ref={tab1Ref}>
        <div
          style={{
            maxWidth: "500px",
            margin: "0 auto",
            border: "10px solid var(--primary-color)",
          }}
          className="d-flex rounded rounded-5 flex-column justify-content-center align-items-center  p-3"
        >
          <div
            style={{
              borderBottom: "2px solid var(--primary-color)",
              width: "100%",
            }}
            className="d-flex p-4 mb-5 justify-content-center"
          >
            <img
              src="/assets/img/kareify/UWC logo.png"
              alt=""
              height={50}
              // style={{ filter: "brightness(50)" }}
            />
          </div>{" "}
          <h4 className="mt-2 mb-5">
            {(expertDetails?.profile?.first_name ||
              expertDetails?.profile?.last_name) && (
              <span className="m-0 ">
                {[
                  expertDetails?.profile?.first_name.charAt(0).toUpperCase() +
                    expertDetails?.profile?.first_name
                      ?.slice(1)
                      ?.replace(/_/g, " "),
                  expertDetails?.profile?.last_name?.charAt(0).toUpperCase() +
                    expertDetails?.profile?.last_name
                      ?.slice(1)
                      ?.replace(/_/g, " "),
                ]
                  .filter(Boolean)
                  .join(" ")}
              </span>
            )}
          </h4>
          <QRCode
            id={"qrcode"}
            value={`https://www${getSubDomain()}/appdownload/`}
            size={250}
            level={"H"}
            includeMargin={false}
          />
          <h3 className="my-3">Use code : CP25D6 </h3>
          <div className="d-flex my-3 flex-column">
            <h6 className="mt-2 d-flex flex-column align-items-center flex-sm-row gap-3">
              {(expertDetails?.profile?.first_name ||
                expertDetails?.profile?.last_name) && (
                <span className="m-0 ">
                  {[
                    expertDetails?.profile?.country_phone_code +
                      " " +
                      expertDetails?.profile?.mobile,
                    expertDetails?.profile?.email,
                  ]
                    .filter(Boolean)
                    .join("  |  ")}
                </span>
              )}
            </h6>

            <div className="m-auto" style={{ color: "", fontSize: "14px" }}>
              {(expertDetails?.profile?.address ||
                expertDetails?.profile?.city ||
                expertDetails?.profile?.state ||
                expertDetails?.profile?.country ||
                expertDetails?.profile?.zip) && (
                <p className="m-0">
                  {[
                    expertDetails?.profile?.address,
                    expertDetails?.profile?.city,
                    expertDetails?.profile?.state,
                    expertDetails?.profile?.country,
                    expertDetails?.profile?.zip,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </p>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MerchantQr;
