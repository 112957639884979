import { IconButton, Input, Tooltip } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { useAuth } from "../../../auth";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getToken } from "../APIS/clientAPIS";

//JWT
import jwtDecode from "jwt-decode";
import { MenuRounded, Toll } from "@mui/icons-material";
import { validate_token } from "../../core/_request";
import { AppContext } from "../../../context";
import { getSubDomain, handleBrokenImage, isAusDomain } from "../clinicalNotesClinicalRoute/lib/utils";

const ClinicalDashboardHeader = ({ handleHamburger }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [bearerToken, setBearerToken] = useState(
    sessionStorage.getItem("token")
  );
  const { credits, setCredits } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const search = location.search;

  useEffect(() => {
    if (!currentUser) {
      navigate("/auth");
    }
  }, []);

  useEffect(() => {
    if (bearerToken == undefined || bearerToken == null || bearerToken == "") {
      let isMounted = true; // Flag to check if the component is still mounted

      getToken(currentUser?.Emailid).then((res) => {
        setBearerToken(res);
      });
    }
  }, [bearerToken]);

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
    fetchCredits();
  }, []);

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const fetchCredits = async () => {
    const storedData = JSON.parse(localStorage.getItem("profile"));
    if (!storedData?.UserId) return;
    const response = await fetch(
      `https://integrations.uwc.world/users/${storedData?.UserId}/credits`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 200) {
      console.error("An error occurred while fetching credits");
      return;
    }
    const data = await response.json();

    setCredits(data?.data?.available_credits);
  };
  const handleProfileClick = () => {
    navigate("/clinical/settings");

    handleDropdownClose();
  };

  const handleLogoutClick = () => {
    // Perform logout actions here
    logout();
    if (isAusDomain()) {
      window.location.href = `https://www${getSubDomain()}`;
    } else {
      window.location.href = `https://www${getSubDomain()}/clinical-co-pilot/`;
    }

    handleDropdownClose();
  };

  const profileImage = currentUser?.ProfileImage?.replace(
    "https://app.uwc.worldhttps://",
    "https://"
  );

  return (
    <div style={{ background: "#fff", borderBottom: "1px solid #D8D8D8" }}>
      <Navbar key={"sm"} expand={"sm"} style={{ height: "75px" }}>
        <Container fluid className="justify-content-between px-sm-5">
          <div className="d-flex align-items-center gap-5">
            <Navbar.Brand className="d-none d-md-block">
              <div id="uwc_logo"
                onClick={() => navigate("/clinical/home")}
                className="text-decoration-none"
              >
                <img
                  src={process.env.REACT_APP_CLIENT_IMAGE}
                  alt=""
                  height={50}
                />
              </div>
            </Navbar.Brand>
            <Navbar.Brand className="d-md-none d-block">
              <Link id="uwc_logo" to="/" className="text-decoration-none">
                <img
                  src={process.env.REACT_APP_CLIENT_IMAGE}
                  alt=""
                  height={40}
                />
              </Link>
            </Navbar.Brand>

          </div>
          <Nav className="gap-4 d d-none d-lg-flex align-items-baseline align-items-md-center justify-content-end">
            <div className="d-flex align-items-center gap-2">
              <Tooltip

                arrow
                title={
                  <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                    Your available credits
                  </p>
                }
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.black",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "common.white",
                      padding: "10px",
                    },
                  },
                }}
              ><span
                id="credits"
                role="button"
                onClick={() => {
                  navigate("/clinical/settings?view=Credits", {
                    state: { view: "Credits" },
                  });
                }}
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  background: "#FFFAEB",
                  color: "#F79009",
                  border: "2px solid #F79009"
                }}
                className="d-flex  px-2 py-1   gap-2 align-items-center  rounded-pill"
              >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-badge-cent"
                  >
                    <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                    <path d="M12 7v10" />
                    <path d="M15.4 10a4 4 0 1 0 0 4" />
                  </svg>{" "}
                  {credits}
                </span></Tooltip>


              <Button id="profile_picture" onClick={handleDropdownOpen}>
                <img
                  src={profileImage}
                  onError={(e) => {
                    const newLink = handleBrokenImage(profileImage);
                    e.target.src = newLink
                  }}
                  width={45}
                  height={45}
                  alt=""
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
              >
                <MenuItem id="view_profile" onClick={handleProfileClick} sx={{ width: "200px" }}>
                  Profile
                </MenuItem>
                <MenuItem id="logout" onClick={handleLogoutClick} sx={{ width: "200px" }}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Nav>
          <IconButton id="hamburger" onClick={handleHamburger} className="d-lg-none">
            <MenuRounded sx={{ width: "30px", height: "30px" }} />
          </IconButton>
        </Container>
      </Navbar>
    </div>
  );
};

export default ClinicalDashboardHeader;
