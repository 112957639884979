export const TGAdata = [
    {
        "id": 78264,
        "name": "Rachel Green",
        "startDate": "2024-01-30",
        "age": 20,
        "gender": "Male",
        "visitDate": "2024-07-01",
        "thcDose": 51,
        "category": "Category 3"
    },
    {
        "id": 15669,
        "name": "Steve Jobs",
        "startDate": "2024-02-26",
        "age": 46,
        "gender": "Male",
        "visitDate": "2024-01-12",
        "thcDose": 22,
        "category": "Category 2"
    },
    {
        "id": 40191,
        "name": "Fiona Green",
        "startDate": "2024-06-30",
        "age": 37,
        "gender": "Female",
        "visitDate": "2024-05-25",
        "thcDose": 26,
        "category": "Category 3"
    },
    {
        "id": 56007,
        "name": "Victor Hugo",
        "startDate": "2023-11-02",
        "age": 62,
        "gender": "Male",
        "visitDate": "2024-03-21",
        "thcDose": 88,
        "category": "Category 3"
    },
    {
        "id": 82837,
        "name": "Quincy Jones",
        "startDate": "2024-04-10",
        "age": 30,
        "gender": "Female",
        "visitDate": "2024-03-26",
        "thcDose": 74,
        "category": "Category 4"
    },
    {
        "id": 27745,
        "name": "Isaac Newton",
        "startDate": "2024-07-23",
        "age": 36,
        "gender": "Male",
        "visitDate": "2024-05-17",
        "thcDose": 38,
        "category": "Category 3"
    },
    {
        "id": 81385,
        "name": "Ethan Davis",
        "startDate": "2023-10-21",
        "age": 54,
        "gender": "Male",
        "visitDate": "2024-06-21",
        "thcDose": 49,
        "category": "Category 1"
    },
    {
        "id": 76062,
        "name": "Tina Fey",
        "startDate": "2024-09-30",
        "age": 27,
        "gender": "Male",
        "visitDate": "2024-08-24",
        "thcDose": 92,
        "category": "Category 2"
    },
    {
        "id": 24592,
        "name": "Steve Jobs",
        "startDate": "2024-08-11",
        "age": 70,
        "gender": "Female",
        "visitDate": "2023-10-25",
        "thcDose": 52,
        "category": "Category 2"
    },
    {
        "id": 49372,
        "name": "Tina Fey",
        "startDate": "2024-08-11",
        "age": 55,
        "gender": "Female",
        "visitDate": "2023-10-20",
        "thcDose": 68,
        "category": "Category 2"
    },
    {
        "id": 38188,
        "name": "Luna Lovegood",
        "startDate": "2023-11-08",
        "age": 46,
        "gender": "Male",
        "visitDate": "2024-07-22",
        "thcDose": 72,
        "category": "Category 5"
    },
    {
        "id": 51456,
        "name": "John Doe",
        "startDate": "2024-01-23",
        "age": 45,
        "gender": "Female",
        "visitDate": "2023-11-15",
        "thcDose": 81,
        "category": "Category 2"
    },
    {
        "id": 28499,
        "name": "Rachel Green",
        "startDate": "2024-04-13",
        "age": 42,
        "gender": "Female",
        "visitDate": "2024-03-25",
        "thcDose": 71,
        "category": "Category 4"
    },
    {
        "id": 54404,
        "name": "Paul McCartney",
        "startDate": "2024-10-02",
        "age": 65,
        "gender": "Male",
        "visitDate": "2024-06-10",
        "thcDose": 51,
        "category": "Category 5"
    },
    {
        "id": 69204,
        "name": "Quincy Jones",
        "startDate": "2023-11-25",
        "age": 30,
        "gender": "Male",
        "visitDate": "2023-11-09",
        "thcDose": 72,
        "category": "Category 2"
    },
    {
        "id": 25359,
        "name": "Bob Brown",
        "startDate": "2024-09-25",
        "age": 48,
        "gender": "Female",
        "visitDate": "2024-06-14",
        "thcDose": 56,
        "category": "Category 3"
    },
    {
        "id": 20413,
        "name": "Bob Brown",
        "startDate": "2024-05-17",
        "age": 35,
        "gender": "Female",
        "visitDate": "2023-11-14",
        "thcDose": 58,
        "category": "Category 1"
    },
    {
        "id": 70050,
        "name": "Kevin Bacon",
        "startDate": "2024-06-24",
        "age": 29,
        "gender": "Female",
        "visitDate": "2024-08-20",
        "thcDose": 38,
        "category": "Category 3"
    },
    {
        "id": 18420,
        "name": "Mona Lisa",
        "startDate": "2024-02-05",
        "age": 32,
        "gender": "Female",
        "visitDate": "2024-04-24",
        "thcDose": 40,
        "category": "Category 4"
    },
    {
        "id": 11525,
        "name": "Judy Garland",
        "startDate": "2023-11-24",
        "age": 39,
        "gender": "Male",
        "visitDate": "2023-12-08",
        "thcDose": 27,
        "category": "Category 2"
    },
    {
        "id": 45140,
        "name": "Diana Black",
        "startDate": "2024-05-31",
        "age": 69,
        "gender": "Female",
        "visitDate": "2024-02-26",
        "thcDose": 66,
        "category": "Category 3"
    },
    {
        "id": 90820,
        "name": "Judy Garland",
        "startDate": "2024-01-29",
        "age": 70,
        "gender": "Female",
        "visitDate": "2023-12-30",
        "thcDose": 60,
        "category": "Category 4"
    },
    {
        "id": 72401,
        "name": "Mona Lisa",
        "startDate": "2024-01-26",
        "age": 39,
        "gender": "Female",
        "visitDate": "2024-02-17",
        "thcDose": 49,
        "category": "Category 2"
    },
    {
        "id": 34357,
        "name": "Charlie White",
        "startDate": "2023-12-07",
        "age": 26,
        "gender": "Female",
        "visitDate": "2023-10-28",
        "thcDose": 10,
        "category": "Category 5"
    },
    {
        "id": 50074,
        "name": "Rachel Green",
        "startDate": "2024-08-21",
        "age": 37,
        "gender": "Male",
        "visitDate": "2023-12-07",
        "thcDose": 40,
        "category": "Category 1"
    },
    {
        "id": 46528,
        "name": "Bob Brown",
        "startDate": "2024-05-19",
        "age": 47,
        "gender": "Female",
        "visitDate": "2024-02-24",
        "thcDose": 71,
        "category": "Category 1"
    },
    {
        "id": 84423,
        "name": "Uma Thurman",
        "startDate": "2024-10-06",
        "age": 38,
        "gender": "Male",
        "visitDate": "2024-07-21",
        "thcDose": 74,
        "category": "Category 2"
    },
    {
        "id": 90335,
        "name": "Alice Johnson",
        "startDate": "2024-10-05",
        "age": 45,
        "gender": "Female",
        "visitDate": "2024-09-22",
        "thcDose": 75,
        "category": "Category 5"
    },
    {
        "id": 70859,
        "name": "Will Smith",
        "startDate": "2023-12-06",
        "age": 44,
        "gender": "Male",
        "visitDate": "2023-11-23",
        "thcDose": 10,
        "category": "Category 2"
    },
    {
        "id": 49475,
        "name": "Jane Smith",
        "startDate": "2024-01-29",
        "age": 30,
        "gender": "Male",
        "visitDate": "2024-01-10",
        "thcDose": 31,
        "category": "Category 1"
    },
    {
        "id": 93670,
        "name": "Rachel Green",
        "startDate": "2024-07-04",
        "age": 69,
        "gender": "Male",
        "visitDate": "2024-08-16",
        "thcDose": 17,
        "category": "Category 5"
    },
    {
        "id": 20029,
        "name": "Luna Lovegood",
        "startDate": "2023-10-18",
        "age": 68,
        "gender": "Female",
        "visitDate": "2024-08-11",
        "thcDose": 53,
        "category": "Category 5"
    },
    {
        "id": 40831,
        "name": "Judy Garland",
        "startDate": "2023-10-29",
        "age": 59,
        "gender": "Female",
        "visitDate": "2024-07-04",
        "thcDose": 36,
        "category": "Category 4"
    },
    {
        "id": 95729,
        "name": "Fiona Green",
        "startDate": "2023-11-29",
        "age": 41,
        "gender": "Female",
        "visitDate": "2024-08-10",
        "thcDose": 75,
        "category": "Category 1"
    },
    {
        "id": 58112,
        "name": "Isaac Newton",
        "startDate": "2023-10-20",
        "age": 41,
        "gender": "Female",
        "visitDate": "2024-06-10",
        "thcDose": 72,
        "category": "Category 4"
    },
    {
        "id": 63237,
        "name": "Olivia Wilde",
        "startDate": "2024-01-29",
        "age": 69,
        "gender": "Male",
        "visitDate": "2024-03-30",
        "thcDose": 90,
        "category": "Category 4"
    },
    {
        "id": 50674,
        "name": "Tina Fey",
        "startDate": "2023-10-30",
        "age": 57,
        "gender": "Male",
        "visitDate": "2024-04-10",
        "thcDose": 59,
        "category": "Category 3"
    },
    {
        "id": 72048,
        "name": "Olivia Wilde",
        "startDate": "2024-05-16",
        "age": 26,
        "gender": "Male",
        "visitDate": "2024-09-26",
        "thcDose": 96,
        "category": "Category 5"
    },
    {
        "id": 37023,
        "name": "Rachel Green",
        "startDate": "2024-02-29",
        "age": 50,
        "gender": "Female",
        "visitDate": "2023-11-08",
        "thcDose": 18,
        "category": "Category 3"
    },
    {
        "id": 45252,
        "name": "George Miller",
        "startDate": "2023-11-09",
        "age": 45,
        "gender": "Male",
        "visitDate": "2024-07-24",
        "thcDose": 100,
        "category": "Category 1"
    },
    {
        "id": 40805,
        "name": "Jane Smith",
        "startDate": "2024-09-01",
        "age": 57,
        "gender": "Female",
        "visitDate": "2024-05-28",
        "thcDose": 75,
        "category": "Category 5"
    },
    {
        "id": 68752,
        "name": "Uma Thurman",
        "startDate": "2024-03-02",
        "age": 22,
        "gender": "Female",
        "visitDate": "2024-01-24",
        "thcDose": 44,
        "category": "Category 5"
    },
    {
        "id": 42685,
        "name": "Rachel Green",
        "startDate": "2023-12-24",
        "age": 23,
        "gender": "Female",
        "visitDate": "2024-01-08",
        "thcDose": 71,
        "category": "Category 2"
    },
    {
        "id": 85704,
        "name": "Uma Thurman",
        "startDate": "2024-07-12",
        "age": 69,
        "gender": "Female",
        "visitDate": "2024-01-26",
        "thcDose": 99,
        "category": "Category 1"
    },
    {
        "id": 80778,
        "name": "Hannah Wilson",
        "startDate": "2024-06-12",
        "age": 56,
        "gender": "Female",
        "visitDate": "2024-01-23",
        "thcDose": 75,
        "category": "Category 3"
    },
    {
        "id": 28403,
        "name": "Tina Fey",
        "startDate": "2024-05-03",
        "age": 68,
        "gender": "Male",
        "visitDate": "2024-10-07",
        "thcDose": 19,
        "category": "Category 4"
    },
    {
        "id": 70067,
        "name": "Charlie White",
        "startDate": "2024-08-15",
        "age": 46,
        "gender": "Female",
        "visitDate": "2023-12-07",
        "thcDose": 39,
        "category": "Category 1"
    },
    {
        "id": 51277,
        "name": "Rachel Green",
        "startDate": "2023-11-07",
        "age": 43,
        "gender": "Male",
        "visitDate": "2024-03-05",
        "thcDose": 39,
        "category": "Category 2"
    },
    {
        "id": 91112,
        "name": "Victor Hugo",
        "startDate": "2024-01-14",
        "age": 59,
        "gender": "Male",
        "visitDate": "2024-04-10",
        "thcDose": 18,
        "category": "Category 5"
    },
    {
        "id": 73921,
        "name": "Tina Fey",
        "startDate": "2024-03-20",
        "age": 45,
        "gender": "Male",
        "visitDate": "2024-05-07",
        "thcDose": 42,
        "category": "Category 5"
    },
    {
        "id": 76911,
        "name": "Bob Brown",
        "startDate": "2024-06-09",
        "age": 29,
        "gender": "Female",
        "visitDate": "2024-01-29",
        "thcDose": 10,
        "category": "Category 2"
    },
    {
        "id": 80374,
        "name": "Charlie White",
        "startDate": "2023-12-28",
        "age": 27,
        "gender": "Male",
        "visitDate": "2024-02-04",
        "thcDose": 10,
        "category": "Category 4"
    },
    {
        "id": 33292,
        "name": "Quincy Jones",
        "startDate": "2024-03-21",
        "age": 67,
        "gender": "Female",
        "visitDate": "2024-03-31",
        "thcDose": 76,
        "category": "Category 1"
    },
    {
        "id": 96886,
        "name": "Hannah Wilson",
        "startDate": "2023-12-06",
        "age": 61,
        "gender": "Female",
        "visitDate": "2024-08-21",
        "thcDose": 32,
        "category": "Category 3"
    },
    {
        "id": 92400,
        "name": "Paul McCartney",
        "startDate": "2024-02-19",
        "age": 48,
        "gender": "Male",
        "visitDate": "2023-12-03",
        "thcDose": 97,
        "category": "Category 5"
    },
    {
        "id": 91146,
        "name": "Nathan Drake",
        "startDate": "2024-03-15",
        "age": 31,
        "gender": "Female",
        "visitDate": "2024-05-10",
        "thcDose": 66,
        "category": "Category 3"
    },
    {
        "id": 96134,
        "name": "Mona Lisa",
        "startDate": "2024-09-02",
        "age": 58,
        "gender": "Male",
        "visitDate": "2024-03-14",
        "thcDose": 29,
        "category": "Category 5"
    },
    {
        "id": 82956,
        "name": "George Miller",
        "startDate": "2024-01-19",
        "age": 66,
        "gender": "Male",
        "visitDate": "2024-06-23",
        "thcDose": 77,
        "category": "Category 4"
    },
    {
        "id": 86924,
        "name": "Rachel Green",
        "startDate": "2024-01-17",
        "age": 21,
        "gender": "Male",
        "visitDate": "2024-05-24",
        "thcDose": 88,
        "category": "Category 5"
    },
    {
        "id": 89215,
        "name": "Isaac Newton",
        "startDate": "2024-05-16",
        "age": 68,
        "gender": "Female",
        "visitDate": "2023-11-28",
        "thcDose": 26,
        "category": "Category 1"
    },
    {
        "id": 23669,
        "name": "Rachel Green",
        "startDate": "2024-06-07",
        "age": 40,
        "gender": "Female",
        "visitDate": "2023-12-28",
        "thcDose": 31,
        "category": "Category 2"
    },
    {
        "id": 12969,
        "name": "Fiona Green",
        "startDate": "2024-06-21",
        "age": 57,
        "gender": "Female",
        "visitDate": "2024-05-25",
        "thcDose": 50,
        "category": "Category 2"
    },
    {
        "id": 93812,
        "name": "Jane Smith",
        "startDate": "2023-11-28",
        "age": 61,
        "gender": "Male",
        "visitDate": "2023-12-18",
        "thcDose": 87,
        "category": "Category 3"
    },
    {
        "id": 54370,
        "name": "Luna Lovegood",
        "startDate": "2024-06-14",
        "age": 65,
        "gender": "Male",
        "visitDate": "2024-05-21",
        "thcDose": 72,
        "category": "Category 1"
    },
    {
        "id": 94261,
        "name": "Tina Fey",
        "startDate": "2023-10-22",
        "age": 60,
        "gender": "Male",
        "visitDate": "2024-02-17",
        "thcDose": 54,
        "category": "Category 1"
    },
    {
        "id": 35412,
        "name": "Jane Smith",
        "startDate": "2024-03-20",
        "age": 53,
        "gender": "Male",
        "visitDate": "2024-07-15",
        "thcDose": 87,
        "category": "Category 4"
    },
    {
        "id": 41606,
        "name": "Mona Lisa",
        "startDate": "2024-01-06",
        "age": 61,
        "gender": "Female",
        "visitDate": "2024-10-09",
        "thcDose": 83,
        "category": "Category 1"
    },
    {
        "id": 16729,
        "name": "Ethan Davis",
        "startDate": "2024-05-16",
        "age": 58,
        "gender": "Male",
        "visitDate": "2024-07-20",
        "thcDose": 50,
        "category": "Category 2"
    },
    {
        "id": 54386,
        "name": "Paul McCartney",
        "startDate": "2023-12-12",
        "age": 56,
        "gender": "Male",
        "visitDate": "2024-05-10",
        "thcDose": 89,
        "category": "Category 5"
    },
    {
        "id": 21737,
        "name": "Nathan Drake",
        "startDate": "2023-12-24",
        "age": 70,
        "gender": "Male",
        "visitDate": "2024-02-20",
        "thcDose": 49,
        "category": "Category 5"
    },
    {
        "id": 53491,
        "name": "Charlie White",
        "startDate": "2023-12-16",
        "age": 60,
        "gender": "Female",
        "visitDate": "2024-07-01",
        "thcDose": 34,
        "category": "Category 2"
    },
    {
        "id": 27385,
        "name": "Olivia Wilde",
        "startDate": "2023-12-24",
        "age": 44,
        "gender": "Female",
        "visitDate": "2024-02-18",
        "thcDose": 95,
        "category": "Category 2"
    },
    {
        "id": 25263,
        "name": "Charlie White",
        "startDate": "2023-12-28",
        "age": 58,
        "gender": "Female",
        "visitDate": "2023-12-22",
        "thcDose": 78,
        "category": "Category 1"
    },
    {
        "id": 28317,
        "name": "Kevin Bacon",
        "startDate": "2023-11-12",
        "age": 31,
        "gender": "Male",
        "visitDate": "2024-04-28",
        "thcDose": 15,
        "category": "Category 5"
    },
    {
        "id": 60396,
        "name": "Luna Lovegood",
        "startDate": "2024-05-13",
        "age": 35,
        "gender": "Female",
        "visitDate": "2024-03-24",
        "thcDose": 97,
        "category": "Category 5"
    },
    {
        "id": 94903,
        "name": "Nathan Drake",
        "startDate": "2024-07-30",
        "age": 34,
        "gender": "Male",
        "visitDate": "2024-04-25",
        "thcDose": 27,
        "category": "Category 1"
    },
    {
        "id": 50861,
        "name": "Quincy Jones",
        "startDate": "2024-02-09",
        "age": 49,
        "gender": "Female",
        "visitDate": "2024-10-03",
        "thcDose": 65,
        "category": "Category 4"
    },
    {
        "id": 29008,
        "name": "Paul McCartney",
        "startDate": "2024-06-10",
        "age": 56,
        "gender": "Female",
        "visitDate": "2024-01-23",
        "thcDose": 77,
        "category": "Category 3"
    },
    {
        "id": 80604,
        "name": "Quincy Jones",
        "startDate": "2024-08-07",
        "age": 23,
        "gender": "Male",
        "visitDate": "2024-02-14",
        "thcDose": 78,
        "category": "Category 3"
    },
    {
        "id": 20739,
        "name": "Steve Jobs",
        "startDate": "2024-08-19",
        "age": 44,
        "gender": "Female",
        "visitDate": "2024-05-23",
        "thcDose": 47,
        "category": "Category 1"
    },
    {
        "id": 20634,
        "name": "Will Smith",
        "startDate": "2024-04-12",
        "age": 38,
        "gender": "Male",
        "visitDate": "2024-06-11",
        "thcDose": 11,
        "category": "Category 5"
    },
    {
        "id": 97947,
        "name": "Will Smith",
        "startDate": "2024-07-10",
        "age": 66,
        "gender": "Female",
        "visitDate": "2024-01-10",
        "thcDose": 61,
        "category": "Category 2"
    },
    {
        "id": 52349,
        "name": "Isaac Newton",
        "startDate": "2024-08-05",
        "age": 45,
        "gender": "Female",
        "visitDate": "2024-01-12",
        "thcDose": 16,
        "category": "Category 4"
    },
    {
        "id": 11500,
        "name": "Rachel Green",
        "startDate": "2024-10-13",
        "age": 51,
        "gender": "Female",
        "visitDate": "2023-11-19",
        "thcDose": 48,
        "category": "Category 4"
    },
    {
        "id": 91848,
        "name": "Tina Fey",
        "startDate": "2024-10-08",
        "age": 44,
        "gender": "Female",
        "visitDate": "2024-07-06",
        "thcDose": 79,
        "category": "Category 1"
    },
    {
        "id": 52471,
        "name": "George Miller",
        "startDate": "2024-01-31",
        "age": 25,
        "gender": "Male",
        "visitDate": "2024-08-12",
        "thcDose": 77,
        "category": "Category 5"
    },
    {
        "id": 27143,
        "name": "Jane Smith",
        "startDate": "2024-04-25",
        "age": 64,
        "gender": "Male",
        "visitDate": "2024-04-30",
        "thcDose": 15,
        "category": "Category 3"
    },
    {
        "id": 64824,
        "name": "Bob Brown",
        "startDate": "2024-02-07",
        "age": 58,
        "gender": "Female",
        "visitDate": "2023-12-26",
        "thcDose": 35,
        "category": "Category 2"
    },
    {
        "id": 61911,
        "name": "George Miller",
        "startDate": "2024-03-30",
        "age": 57,
        "gender": "Male",
        "visitDate": "2024-02-22",
        "thcDose": 26,
        "category": "Category 5"
    },
    {
        "id": 24529,
        "name": "Luna Lovegood",
        "startDate": "2024-05-31",
        "age": 25,
        "gender": "Male",
        "visitDate": "2023-12-14",
        "thcDose": 29,
        "category": "Category 1"
    },
    {
        "id": 26911,
        "name": "Steve Jobs",
        "startDate": "2024-03-06",
        "age": 20,
        "gender": "Male",
        "visitDate": "2024-01-04",
        "thcDose": 41,
        "category": "Category 5"
    },
    {
        "id": 76241,
        "name": "Steve Jobs",
        "startDate": "2024-01-02",
        "age": 23,
        "gender": "Female",
        "visitDate": "2023-12-13",
        "thcDose": 15,
        "category": "Category 1"
    },
    {
        "id": 94079,
        "name": "Victor Hugo",
        "startDate": "2023-12-18",
        "age": 21,
        "gender": "Male",
        "visitDate": "2024-05-25",
        "thcDose": 52,
        "category": "Category 5"
    },
    {
        "id": 74107,
        "name": "Tina Fey",
        "startDate": "2024-01-15",
        "age": 49,
        "gender": "Male",
        "visitDate": "2024-07-16",
        "thcDose": 81,
        "category": "Category 4"
    },
    {
        "id": 28572,
        "name": "Jane Smith",
        "startDate": "2024-03-12",
        "age": 65,
        "gender": "Male",
        "visitDate": "2024-03-26",
        "thcDose": 84,
        "category": "Category 3"
    },
    {
        "id": 76657,
        "name": "Judy Garland",
        "startDate": "2024-02-10",
        "age": 47,
        "gender": "Male",
        "visitDate": "2024-08-15",
        "thcDose": 86,
        "category": "Category 3"
    },
    {
        "id": 69822,
        "name": "Will Smith",
        "startDate": "2024-01-29",
        "age": 37,
        "gender": "Male",
        "visitDate": "2024-02-12",
        "thcDose": 20,
        "category": "Category 2"
    },
    {
        "id": 97207,
        "name": "Ethan Davis",
        "startDate": "2024-04-08",
        "age": 65,
        "gender": "Female",
        "visitDate": "2023-10-28",
        "thcDose": 82,
        "category": "Category 5"
    },
    {
        "id": 18369,
        "name": "Will Smith",
        "startDate": "2024-07-05",
        "age": 31,
        "gender": "Female",
        "visitDate": "2024-05-01",
        "thcDose": 14,
        "category": "Category 2"
    },
    {
        "id": 73400,
        "name": "Judy Garland",
        "startDate": "2024-09-24",
        "age": 48,
        "gender": "Male",
        "visitDate": "2024-03-12",
        "thcDose": 11,
        "category": "Category 1"
    }
]