import { CheckCircleOutline } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { getSubDomain } from "../clinicalNotesClinicalRoute/lib/utils";

function CautionContent() {
  return (
    <List sx={{ pl: 0 }}>
      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText primary="The Clinical Copilot is not intended to function as a medical device. Any output must be verified by a healthcare professional (HCP), and no direct diagnosis or prescription should be issued based solely on this output." />
      </ListItem>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText primary="Only licensed psychiatrists, medical doctors, or healthcare providers with valid authority may prescribe medications." />
      </ListItem>

      <List component="div" disablePadding sx={{ pl: 4 }}>
        <ListItem
          sx={{ display: "flex", alignItems: "flex-start", pl: 2, py: 0 }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#17B26A", fontSize: "18px", mr: 1, mt: 0.6 }}
          >
            •
          </Typography>
          <ListItemText primary="Non-medical professionals, including psychotherapists, counsellors, and psychologists, cannot prescribe medications, even if the Clinical Copilot suggests treatments." />
        </ListItem>
      </List>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText primary="Due to the nature of large language models (LLMs) and generative AI, outputs may not always be reliable. Outputs may contain:" />
      </ListItem>

      <List component="div" disablePadding sx={{ pl: 4 }}>
        {[
          "Incorrect information",
          "Biases or stereotypes",
          "Potentially harmful content",
        ].map((text, index) => (
          <ListItem
            key={index}
            sx={{ display: "flex", alignItems: "flex-start", pl: 2, py: 0 }}
          >
            <Typography
              variant="body2"
              sx={{ color: "#17B26A", fontSize: "18px", mr: 1, mt: 0.6 }}
            >
              •
            </Typography>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText primary="All summaries, answers, or recommendations are intended as drafts for review and should not be considered final." />
      </ListItem>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              If the Clinical Copilot detects content violating our policies,
              including the
              <a
                target="blank"
                href={`https://legal${getSubDomain()}/ai-use-policy`}
              >
                {" "}
                Generative AI Prohibited Use Policy
              </a>
              :
            </>
          }
        />
      </ListItem>

      <List component="div" disablePadding sx={{ pl: 4 }}>
        {[
          "It may not return a response.",
          "When responses are generated, users are responsible for verifying them and for any clinical outcomes.",
        ].map((text, index) => (
          <ListItem
            key={index}
            sx={{ display: "flex", alignItems: "flex-start", pl: 2, py: 0 }}
          >
            <Typography
              variant="body2"
              sx={{ color: "#17B26A", fontSize: "18px", mr: 1, mt: 0.6 }}
            >
              •
            </Typography>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText primary="For Q&A purposes, the Clinical Copilot is intended strictly as an educational tool for medical training or to reinforce the HCP’s existing clinical knowledge." />
      </ListItem>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText primary="Output formatting may vary from the exact input prompt, so prompts should be designed to account for:" />
      </ListItem>

      <List component="div" disablePadding sx={{ pl: 4 }}>
        <ListItem
          sx={{ display: "flex", alignItems: "flex-start", pl: 2, py: 0 }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#17B26A", fontSize: "18px", mr: 1, mt: 0.6 }}
          >
            •
          </Typography>
          <ListItemText primary="Potential deviations in format (e.g., dashes in field names or capitalization variations)." />
        </ListItem>
      </List>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              By using this tool, users acknowledge that they have read and
              understood the
              <a
                target="blank"
                href={`https://legal${getSubDomain()}/disclaimer-ccop`}
              >
                {" "}
                Disclaimer for {process.env.REACT_APP_CLIENT_NAME} Clinical
                Copilot
              </a>
              .
            </>
          }
        />
      </ListItem>

      <ListItem sx={{ alignItems: "flex-start", py: 0 }}>
        <ListItemIcon sx={{ minWidth: "30px", mt: 0.8 }}>
          <CheckCircleOutline sx={{ color: "#17B26A", fontSize: "20px" }} />
        </ListItemIcon>
        <ListItemText primary="The Clinical Copilot is not intended to be used as an electronic medical record (EMR) or similar system." />
      </ListItem>
    </List>
  );
}

export default CautionContent;
