import React, { useContext, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";
import { AppContext } from "../../../../context";
import { MicIconSVG, NotePadSVG } from "../ClinicalNoteSVGs";
import { FileUploader } from "react-drag-drop-files";
import "../../../../styles/dialogs/FloatingService.css";

// Component
import AssistantModal from "./AssistantModal";

// API
import {
  AppendToContext,
  InsertRagContext,
  LAMA_OCR_Document,
} from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { convertBlobToBase64 } from "../lib/utils";
import { fetchSTTWhisper } from "../../APIS/clientAPIS";

// MUI

import {
  AssistantOutlined,
  Close,
  CloudUploadOutlined,
  ContentCopy,
  Delete,
  DeleteOutlineOutlined,
  Loop,
  Mic,
  ReportProblemOutlined,
  Stop,
  TramSharp,
} from "@mui/icons-material";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import DisclaimerModal from "../../modals/DisclaimerModal";
import AcceptCCOPDisclaimer from "../../modals/AcceptCCOPDisclaimer";
import { acceptTNC, getDisclaimer, getTNC } from "../../../core/_request";
import { useAuth } from "../../../../auth";
import NewTranscriptionDialog from "../Dialogs/NewTranscriptionDialog";

const LinearProgressBarWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const FloatingService = ({ screen, data }) => {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);

  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [assistantModalOpen, setAssistantModalOpen] = useState(false);
  const [TranscriptionDialog, setTranscriptionDialog] = useState(false);

  const openCustomModal = () => {
    setTranscriptionDialog(true);
  };


  const handleOpen = () => {
    setAssistantModalOpen(true);
  };
  const handleClose = () => {
    setAssistantModalOpen(false);
  };


  return (
    <>
      <div className="floatingContainer">
        {!expertClinicalNotesData?.data?.submitted && (
          <div
            className="assistantIcon d-flex align-items-center gap-2 my-2"
            onMouseEnter={() => setIsHovered1(true)}
            onMouseLeave={() => setIsHovered1(false)}
            onClick={handleOpen}
            style={{
              background:
                expertClinicalNotesData?.data?.assistant?.alerts?.alert_number >
                  0
                  ? "#F63D68"
                  : expertClinicalNotesData?.data?.assistant?.medications
                    ?.alert_number > 0
                    ? "#FB6514"
                    : expertClinicalNotesData?.data?.assistant?.diagnostics
                      ?.alert_number > 0
                      ? "#FDB022"
                      : "#12B76A",
              width: "fit-content",
            }}
          >
            <div
              className="bg-white rounded-circle p-1"
              style={{ width: "34px", height: "34px" }}
            >
              {expertClinicalNotesData?.data?.assistant?.alerts?.alert_number >
                0 ? (
                <ReportProblemOutlined
                  fontSize="medium"
                  sx={{ fill: "#F63D68" }}
                />
              ) : expertClinicalNotesData?.data?.assistant?.medications
                ?.alert_number > 0 ? (
                <img width={20} src="/assets/img/elements/medication.png" />
              ) : expertClinicalNotesData?.data?.assistant?.diagnostics
                ?.alert_number > 0 ? (
                <img width={20} src="/assets/img/elements/diagnostics.png" />
              ) : (
                <AssistantOutlined fontSize="medium" sx={{ fill: "#12B76A" }} />
              )}
            </div>
            {isHovered1 &&
              (expertClinicalNotesData?.data?.assistant?.alerts?.alert_number >
                0 ? (
                <span className="assistantText">
                  {
                    expertClinicalNotesData?.data?.assistant?.alerts
                      ?.alert_number
                  }{" "}
                  Alerts
                </span>
              ) : expertClinicalNotesData?.data?.assistant?.medications
                ?.alert_number > 0 ? (
                <span className="assistantText">
                  {
                    expertClinicalNotesData?.data?.assistant?.medications
                      ?.alert_number
                  }{" "}
                  Medication
                </span>
              ) : expertClinicalNotesData?.data?.assistant?.diagnostics
                ?.alert_number > 0 ? (
                <span className="assistantText">
                  {
                    expertClinicalNotesData?.data?.assistant?.diagnostics
                      ?.alert_number
                  }{" "}
                  Diagnostic
                </span>
              ) : (
                <span className="assistantText">Assistant</span>
              ))}
          </div>
        )}

        {expertClinicalNotesData?.data?.submitted && (
          <div
            id="assistant"
            className="assistantIcon d-flex align-items-center gap-2 my-2"
            onMouseEnter={() => setIsHovered1(true)}
            onMouseLeave={() => setIsHovered1(false)}
            onClick={handleOpen}
            style={{
              background: "#12B76A",
              width: "fit-content",
            }}
          >
            <div className="bg-white rounded-circle p-1">
              <AssistantOutlined fontSize="medium" sx={{ fill: "#12B76A" }} />
            </div>
            {isHovered1 && <span className="assistantText">Assistant</span>}
          </div>
        )}
        {!expertClinicalNotesData?.data?.submitted && (
          <button
            id="generate-note-via-voice-document"
            className="assistantIcon d-flex align-items-center gap-2 my-2"
            onMouseEnter={() => setIsHovered2(true)}
            onMouseLeave={() => setIsHovered2(false)}
            onClick={openCustomModal}
            style={{
              background: "var(--primary-color)",
              width: "fit-content",
              color: "#fff",
              border: "none",
            }}
          >
            {MicIconSVG}
            {isHovered2 && (
              <span className="assistantText">
                Generate note via voice/document
              </span>
            )}
          </button>
        )}
      </div>

      <AssistantModal show={assistantModalOpen} close={handleClose} />
      <NewTranscriptionDialog
        onSuccess={() => {
          setTranscriptionDialog(false);
        }}
        data={{
          _id: expertClinicalNotesData?.headerData?.session_id,
          session_date: expertClinicalNotesData?.headerData?.appointment_date,
          client: {
            user_id: expertClinicalNotesData?.headerData?.user_id,
          },
          expert: {
            user_id: expertClinicalNotesData?.headerData?.expert_id,
          }
        }}
        open={TranscriptionDialog}
        handleClose={() => setTranscriptionDialog(true)}
      />
    </>
  );
};

export default FloatingService;
