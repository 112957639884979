import { Delete, PictureAsPdfRounded, Refresh } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { imageSVG, pdfSVG } from "../ClinicalNoteSVGs";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useSnackbar } from "notistack";
import moment from "moment";
import { storage } from "../../../../firebaseConfig";
import { LAMA_OCR_Document } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { useAuth } from "../../../../auth";
import dayjs from "dayjs";
import Cookies from "js-cookie";
function FileUploaderModal({ transcription, state, data, scrollOnChange }) {
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [transcribedStatus, setTranscribedStatus] = useState([]);
  const fileRef = useRef(null);
  const { currentUser } = useAuth();
  const handleFileChange = (file) => {
    const selectedFiles = [...file];
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    setTranscribedStatus((prevStatus) => [
      ...prevStatus,
      ...Array(selectedFiles.length).fill(false), // Initialize transcribedStatus as false for new files
    ]);
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setTranscribedStatus((prevStatus) =>
      prevStatus.filter((_, i) => i !== index)
    );
  };

  const getFileType = (file) => {
    if (file.type.startsWith("image/")) {
      return "image";
    } else if (file.type === "application/pdf") {
      return "pdf";
    }
    return "other";
  };

  const uploadAuditLog = async (fileUrl, name) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_EXPERT_BASE_URL}clinicalnotes/update-audit-logs`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            token: token,
            "Content-Type": "application/json",
            authenticationToken: Cookies.get("authDataJwt"),
          },
          body: JSON.stringify({
            action_type: "uploaded",
            details: {
              name: name,
              link: fileUrl,
            },
            user_id: `${data?.client?.user_id}`,
            expert_id: `${data?.expert?.user_id}`,
            appointment_id: `${data?._id}`,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Fetching assessment list failed:", error);
      throw error;
    }
  };

  const handleSingleFileUpload = async (file, index = null) => {
    try {
      if (!file) return;

      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
      ];

      if (file.size > 20 * 1024 * 1024) {
        enqueueSnackbar("File size should be less than 20 MB", {
          variant: "error",
        });
        return;
      }

      if (!allowedTypes.includes(file.type)) {
        enqueueSnackbar(
          "Invalid file type. Please select an image or PDF file.",
          {
            variant: "error",
          }
        );
        return;
      }

      setLoading(true);
      // const dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(
        storage,
        `/clinic-data-storage/clients/${currentUser?.Country}/${dayjs().format(
          "YYYY-MMM-DD_HH:mm:ss"
        )}_${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            setLoading(false);
            console.error("Upload failed", error);
            enqueueSnackbar("Failed to upload the file.", { variant: "error" });
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              uploadAuditLog(downloadURL, file.name);
              const res = await LAMA_OCR_Document(downloadURL);
              if (res?.error) {
                enqueueSnackbar(res.message, { variant: "info" });
              } else {
                // Update the transcription status for the current file
                if (index !== null) {
                  setTranscribedStatus((prevStatus) => {
                    const newStatus = [...prevStatus];
                    newStatus[index] = true;
                    return newStatus;
                  });
                }
                const addBody = {
                  uploaded_by: "expert",
                  user_id: `${data?.client?.user_id}`,
                  creator_id: `${data?.expert?.user_id}`,
                  clinical_note_id: `${data?._id}`,

                  doctor_names: currentUser?.Name,
                  response: res?.response,
                  clinical_relevance: res?.clinical_relevance,
                  metadata: res?.metadata,
                };
                AddDocument(addBody);

                resolve(res?.response); // Resolve the transcription result
              }
            } catch (error) {
              enqueueSnackbar("Error during transcription", {
                variant: "error",
              });
              reject(error);
            } finally {
              setLoading(false);
            }
          }
        );
      });
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("Something went wrong.", { variant: "error" });
    }
  };

  // Transcribe all files: Join all responses and then call transcription
  const transcribeAllFiles = async () => {
    if (files.length === 0) {
      enqueueSnackbar("No files to transcribe", { variant: "warning" });
      return;
    }

    setLoading(true);
    try {
      const allTranscriptions = [];
      const updatedStatus = [...transcribedStatus]; // Copy the transcribedStatus array
      for (const [index, file] of files.entries()) {
        // Skip if already transcribed
        if (updatedStatus[index] === true) {
          continue; // Skip to the next file
        }
        const response = await handleSingleFileUpload(file, index); // Pass index to update status
        if (response) {
          allTranscriptions.push(response); // Collect all responses
          updatedStatus[index] = true; // Mark file as transcribed
        }
      }
      setTranscribedStatus(updatedStatus); // Update the transcribed status state
      const joinedTranscription = allTranscriptions.join("\n"); // Join the responses
      transcription(joinedTranscription); // Call transcription with concatenated result
      enqueueSnackbar("All files transcribed successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Error transcribing files.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  // Transcribe single file and call transcription directly
  const handleSingleFile = async (file, index) => {
    try {
      const response = await handleSingleFileUpload(file, index);
      if (response) {
        transcription(response); // Directly pass the transcription result for single file
        enqueueSnackbar("File transcribed successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error("Error transcribing file", error);
    }
  };

  const scrollChatToBottom = () => {
    const chatElement = fileRef.current;
    if (chatElement) {
      // Use setTimeout to allow DOM updates to complete before scrolling
      setTimeout(() => {
        chatElement.scrollTop = chatElement.scrollHeight;
      }, 0);
    }
  };

  useEffect(() => {
    scrollChatToBottom();
    scrollOnChange();
  }, [files]);

  const AddDocument = async (body) => {
    try {
      const storedData = localStorage.getItem("Authorization");
      const token = storedData ? storedData?.split("Bearer ")[1] : null;
      const response = await fetch(
        `https://session-note.uwc.world/clinicalnotes/add-prescription`,
        {
          method: "POST",
          headers: {
            token,
            "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
            DeviceType: "web",
            "Content-Type": "application/json",
            DeviceId: "123",
            Lat: "1",
            Long: "1",
            authenticationToken: Cookies.get("authDataJwt"),
          },
          body: JSON.stringify(body),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div style={{ display: state !== "fileupload" && "none" }} className="p-4">
      <FileUploader
        multiple={true}
        handleChange={handleFileChange}
        name="file"
        types={["jpeg", "png", "jpg", "pdf"]}
        label="Select a file or drag and drop here"
        hoverTitle="Drop it here"
        classes="fileUpload--Modal"
      />

      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        <span style={{ color: "red" }}>*</span> Supported file formats are JPG,
        JPEG, PNG, and PDF.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <span style={{ color: "red" }}>*</span> Maximum file size is 20MB.
      </Typography>

      {files.length > 0 && (
        <div
          style={{
            background: "#F9FAFB",
          }}
          className="w-100 border my-3 rounded-3 p-2 p-md-4"
        >
          <div className="d-flex justify-content-between flex-wrap gap-3 align-items-center">
            <h6 className="m-0">Uploaded Files</h6>
            <div className="d-flex  justify-content-between align-items-center gap-3">
              <Button
                id="notetaker_transcribe_all_files_button"
                className="rounded-3"
                sx={{
                  backgroundColor: "#809b58",
                  textTransform: "capitalize",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#54653b",
                  },
                }}
                onClick={transcribeAllFiles}
                disabled={loading}
              >
                Transcribe All
              </Button>

              <Button
                variant="text"
                sx={{
                  borderRadius: "20px", // Rounded but not too much
                  bgcolor: "#FFFFFF",
                  color: "#414141",
                  fontSize: "14px", // 14px as in the default theme
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#FFFFFF",
                  },
                }}
                className="d-flex border justify-content-center align-items-center gap-2 rounded-3 px-3 "
                onClick={() => setFiles([])}
                disabled={loading}
                id="notetaker_remove_all_files_button"
              >
                Remove All
              </Button>
            </div>
          </div>

          <div
            ref={fileRef}
            style={{ maxHeight: "350px", overflowY: "scroll" }}
            className="mt-3 border-top pb-3"
          >
            {files.map((file, index) => (
              <div
                style={{ background: "#FFFFFF", border: "1px solid #EAECF0" }}
                className="d-flex mt-3 p-3 flex-wrap gap-3  rounded-3 justify-content-between align-items-center"
                key={index}
              >
                <div className="d-flex justify-content-center align-items-center gap-3">
                  {getFileType(file) === "image" && imageSVG}
                  {getFileType(file) === "pdf" && pdfSVG}
                  <div className="d-flex flex-column">
                    {" "}
                    <span>{file.name} </span>{" "}
                    <span style={{ fontSize: "14px" }}>
                      {" "}
                      {Math.round(file?.size / 1024) + " KB"}
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-3">
                  {transcribedStatus[index] ? (
                    <>
                      <Tooltip title="Transcribe again" arrow placement="top">
                        <IconButton
                          id="notetaker_file_again_transcribe_button"
                          onClick={() => handleSingleFile(file, index)}
                        >
                          <Refresh />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <Button
                      variant="text"
                      sx={{
                        borderRadius: "20px", // Rounded but not too much
                        bgcolor: "#FFFFFF",
                        color: "#414141",
                        fontSize: "14px", // 14px as in the default theme
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#FFFFFF",
                        },
                      }}
                      className="d-flex border justify-content-center align-items-center gap-2 rounded-3 px-3 "
                      onClick={() => handleSingleFile(file, index)}
                      id="notetaker_file_transcribe_button"
                    >
                      Transcribe
                    </Button>
                  )}
                  <Tooltip title="Delete" arrow placement="top">
                    <IconButton
                      id="notetaker_file_delete_button"
                      onClick={() => removeFile(index)}
                      disabled={loading}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default FileUploaderModal;
