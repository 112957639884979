import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { CircularProgress, Skeleton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//Icons
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import moment from "moment";

//Upload
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";
import { useAuth } from "../../../../auth";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const languageList = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "Bengali", label: "Bengali" },
  { value: "Japanese", label: "Japanese" },
  { value: "Korean", label: "Korean" },
];

const EditClinicalProfile = ({
  data: {
    address = "",
    city = "",
    country_id = "",
    country_phone_code = "",
    dob = "",
    email = "",
    email_verified = false,
    first_name = "",
    middle_name = "",
    last_name = "",
    gender_id = "",
    id_proof_link = "",
    id_proof_type_id = "",
    image_link = "",
    landmark = "",
    mobile = "",
    state_id = "",
    timezone = "",
    zip = "",
  } = {},
}) => {
  const [gender, setGender] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [country, setCountry] = useState(country_id);
  const [firstName, setFirstName] = useState(first_name);
  const [middleName, setMiddleName] = useState(middle_name);
  const [lastName, setLastName] = useState(last_name);
  const [mobileNumber, setMobileNumber] = useState();
  const [dobDetails, setDobDetails] = useState(dob);
  const [addressDetails, setAddressDetails] = useState(address);
  const [landmarkDetails, setLandmarkDetails] = useState(landmark);
  const [cityName, setCityName] = useState(city);
  const [stateID, setStateID] = useState(0);
  const [zipCode, setZipCode] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [proofIDList, setproofIDList] = useState([]);
  const [proofName, setProofName] = useState();

  const [stateList, setStateList] = useState([]);
  const [countryCallingCode, setCountryCallingCode] = useState("");

  const [profilePicture, setProfilePicture] = useState(image_link);
  //Snackbar
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const defaultImage = "/assets/img/default-profile.jpg";
  const [uploading, setUploading] = useState(false);
  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
  };
  const [genderList, setGenderList] = useState([])
  const userID = JSON.parse(localStorage.getItem("profile"))?.UserId;

  //When values are poplulated
  useEffect(() => {
    setFirstName(first_name);
    setMiddleName(middle_name);
    setLastName(last_name);
    setAddressDetails(address);
    setProfilePicture(image_link);
    setMobileNumber(mobile);
    setStateID(state_id);
    setCityName(city);
    setZipCode(zip);
    setIsLoading(false);
    setDobDetails(dob);
    setGender(gender_id);
    setLandmarkDetails(landmark);
    setCountry(country_id);
  }, [first_name]);

  //Countries List
  useEffect(() => {
    if (!country) return
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://us.uwc.world/new-uwc/country?all=true", {
          method: "GET",
          headers,
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();
        setCountryList(postResponse.data.countries);
        // Handle the response data
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchCountries();
  }, [country]);

  //States List
  useEffect(() => {
    if (!countryList) return
    const fetchStates = async () => {
      try {
        let countryItem = countryList?.filter((item) => item.id === country_id);
        let countryCode = countryItem[0]?.code;

        const response = await fetch(
          `https://us.uwc.world/new-uwc/state/${countryCode}`,
          {
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();
        const statesArray = await postResponse.data?.states;

        setStateList(statesArray);
        // Handle the response data
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchStates();
  }, [countryList]);
  const getGendersFromAPI = async () => {
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/gender`,
        {
          method: "GET",
          headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const postResponse = await response.json();
      if (postResponse?.code === 200) {
        setGenderList(postResponse?.data?.genders);
      }
    }
    catch (error) {
      console.error(error);
      // Handle the error
    }
  };
  //Country Calling Code
  useEffect(() => {
    if (!currentUser) return
    const fetchCountryCallingCode = async () => {
      try {
        let countryItem = countryList?.filter((item) => item.id === country_id);
        let countryCode = countryItem[0]?.code;
        //console.log("countryCode", countryCode)
        if (!countryCode) return
        const response = await fetch(
          `https://us.uwc.world/new-uwc/country/call-code/${countryCode}`,
          {
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();
        const phoneCode = await postResponse?.data?.phone_code;

        //console.log("postResponse CCC", phoneCode);
        setCountryCallingCode(phoneCode);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchCountryCallingCode();
    getGendersFromAPI();
  }, []);



  //ID Proof
  useEffect(() => {
    if (!currentUser) return
    const fetchIDs = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/registration/id-proofs`,
          {
            method: "GET",
            headers,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();

        setproofIDList(postResponse?.data.id_proofs);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchIDs();
  }, []);

  useEffect(() => {
    if (!currentUser) return
    const proofItem = proofIDList?.filter(
      (item) => item.id == id_proof_type_id
    );
    setProofName(proofItem[0]?.name);
  }, [proofIDList]);

  //Firebase login
  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.log("errorMessage",errorMessage)
        // ...
      });
  }, []);
  const handleSelectlanguages = (event) => {
    setSelectedDays(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleCountryChange = (event) => {
    //console.log("EV", event.target.value)
    setCountry(event.target.value);
  };



  //Profile Picture
  const handleChangeProfilePic = async (event) => {
    try {
      let file = Array.from(event.target.files)[0];

      if (
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/jpeg"
      ) {
        setUploading(true);
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            //console.log(`Upload progress: ${percent}%`);
          },
          (error) => {
            console.error("Upload error:", error);
            // Handle upload error if needed
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);

              setProfilePicture(url);
              setUploading(false);
            } catch (error) {
              console.error("Error getting download URL:", error);
              // Handle error if needed
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
      // Handle error if needed
    }
  };

  const handleDeleteProfilePic = () => {
    setProfilePicture(defaultImage);
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
  };
  const handleLasttNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleAddress = (e) => {
    setAddressDetails(e.target.value);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    setMobileNumber(value);
  };
  //console.log("selectedFiles", selectedFiles);

  const handleStateChange = (e) => {
    setStateID(e.target.value);
  };

  const handleCityChange = (e) => {
    setCityName(e.target.value);
  };

  const handleZipChange = (e) => {
    setZipCode(e.target.value);
  };

  const handleUpdateProfile = async () => {

    if (mobileNumber && /^\d{10}$/.test(mobileNumber) === false) {
      enqueueSnackbar("Please enter valid mobile number", {
        variant: "error",
      })
      return;
    }
    const payload = {
      uwc_user_id: userID,
      email: email,
      mobile: mobileNumber,
      image_link: profilePicture,
      address: addressDetails,
      state_id: stateID,
      city: cityName,
      zip: zipCode,
      country_phone_code,
      dob: dobDetails,
      landmark: landmarkDetails,
      gender_id: gender,
      last_name: lastName,
      first_name: firstName,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/update-profile-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const postResponse = await response.json();

      const reponseCode = await postResponse.code;

      if (postResponse.code === 200) {

        const localData = JSON.parse(localStorage.getItem("profile"))
        const newLocalData = {
          ...localData,
          ProfileImage: profilePicture
        };
        localStorage.setItem("profile", JSON.stringify(newLocalData));


        enqueueSnackbar("Profile updated successfully", {
          variant: "success",
        })
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //console.log("stateID", stateID)
  return (
    <>
      <div className="Content--Section">
        <div className="mt-4 d-flex align-items-center gap-3">
          <div className=" position-relative">
            {isLoading || !profilePicture ? (
              <Skeleton
                sx={{ width: "120px", height: "120px", borderRadius: "20px" }}
                variant="rectangular"
              />
            ) : (
              <img
                src={profilePicture}
                width={120}
                height={120}
                alt=""
                loading="lazy"
                style={{
                  borderRadius: "20px",
                  objectFit: "cover",
                  backgroundColor: "rgb(0,0,0,0.07)",
                }}
              />
            )}

            {uploading && <div style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",

            }}>
              <CircularProgress className=" " />
            </div>}


          </div>


          <div>
            <Button
              component="label"
              htmlFor="profile-pic-upload"
              id="change_profile_pic"
              className="change_profile_pic"
              sx={{
                backgroundColor: "#F5F5F5",
                color: "#414141",
                borderRadius: "5px",
                padding: "10px 20px",
                textTransform: "capitalize",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "200px",
              }}
            >
              <input
                id="profile-pic-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleChangeProfilePic}
              />
              <ImageOutlinedIcon />
              Change Profile Pic
            </Button>
            <Button
              onClick={handleDeleteProfilePic}
              sx={{
                backgroundColor: "#D2293D1A",
                color: "#D2293D",
                borderRadius: "5px",
                padding: "10px 20px",
                textTransform: "capitalize",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "200px",
                marginTop: "20px",
              }}
              id="delete_profile_click"
              className="delete_profile_click"
            >
              <DeleteOutlineOutlinedIcon />
              Delete Profile Pic
            </Button>
          </div>
        </div>
      </div>
      <div className="Content--Section">
        <h6>Personal Details</h6>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={4}>
            <TextField
              id="firstname"
              label="First Name"
              value={firstName}
              onChange={handleFirstNameChange}
              variant="outlined"
              fullWidth
              //defaultValue={""}
              
            />
          </Grid>



          <Grid item xs={12} sm={4}>
            <TextField
              id="lastname"
              label="Last Name"
              variant="outlined"
              fullWidth
            
              onChange={handleLasttNameChange}
              value={lastName}
              
            />
          </Grid>

          {/* Gender */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                fullWidth
                labelId="gender-select-label"
                id="gender-select"
                value={gender}
                label="Gender"
                onChange={handleGenderChange}
              >
                {genderList?.map((gender, index) => (
                  <MenuItem value={gender?.id} key={index}>
                    {gender?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="email"
              label=""
              value={email}
              variant="outlined"
              fullWidth
              disabled
              //defaultValue={""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {email_verified && (
                      <DoneOutlinedIcon sx={{ color: "#55A51D" }} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* DOB */}
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(dobDetails)}
                label="Date of birth"
                sx={{ width: "100%", paddingTop: "0" }}
                onChange={(newValue) => {
                  setDobDetails(dayjs(newValue).format("YYYY-MM-DD"));
                }}

              />
            </LocalizationProvider>
          </Grid>

          {/* Language */}
          {/* <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel sx={{ background: "#fff" }}>Language</InputLabel>
              <Select
                fullWidth
                multiple
                value={selectedDays}
                label="Language"
                onChange={handleSelectlanguages}
                renderValue={(selected) => selected.join(", ")}
              >
                {languageList.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    <Checkbox
                      checked={selectedDays.includes(day.value)}
                      name={day.label}
                    />
                    {day.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
      </div>




      <div className="Content--Section">
        <h6>Address Details</h6>

        {/* Address */}
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={12} sm={4}>
            <TextField
              id="address"
              label="Address"
              variant="outlined"
              fullWidth
              value={addressDetails}
              onChange={handleAddress}
            //defaultValue={""}
            />
          </Grid>

          {/* Landmark */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="landmark"
              label="Landmark (Optional)"
              variant="outlined"
              value={landmarkDetails}
              //defaultValue={""}
              onChange={(event) => setLandmarkDetails(event.target.value)}
              fullWidth
            />
          </Grid>

          {/* Country */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="country-select-label">Country</InputLabel>
              <Select
                fullWidth
                labelId="country-select-label"
                id="country-select"
                value={country_id || ""}
                label="country"
                //onChange={handleCountryChange}
                disabled
              >
                {countryList?.map((country) => {
                  return (
                    <MenuItem key={country.code} value={country.id}>
                      {country.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* State */}
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="state-select-label">State</InputLabel>
              <Select
                fullWidth
                labelId="state-select-label"
                id="state-select"
                value={stateID || ""}
                label="state"
                onChange={handleStateChange}
              >
                {stateList?.map((item) => {
                  return (
                    <MenuItem key={item.code} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* City */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="city"
              label="City"
              value={cityName}
              onChange={handleCityChange}
              defaultValue=""
              variant="outlined"
              fullWidth
            />
          </Grid>

          {/* Timezone */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="timezone"
              label="Timezone"
              value={timezone}
              defaultValue=""
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>

          {/* Zipcode */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="zipcode"
              label="Zip Code"
              value={zipCode}
              onChange={handleZipChange}
              defaultValue=""
              variant="outlined"
              inputProps={{ inputMode: "numeric" }}
              fullWidth
            />
          </Grid>

          {/* Mobile */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="mobile"
              label="Mobile (Optional)"
              variant="outlined"
              value={mobileNumber}
              defaultValue={""}
              onChange={handleMobileChange}
              InputProps={{
                inputMode: "numeric",
                startAdornment: (
                  <InputAdornment position="start">
                    {country_phone_code || "+91"}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={4}>
            <TextField
              id="officephone"
              label="Office Phone Number (Optional)"
              variant="outlined"
              fullWidth
            />
          </Grid> */}
        </Grid>

        <Button
          variant="contained"
          disableElevation
          onClick={handleUpdateProfile}
          className="PrimaryCTA update_profile"
          id="update_profile"
          sx={{ marginLeft: "auto", display: "block", marginTop: "30px" }}
        >
          Update Profile
        </Button>
      </div>


    </>
  );
};

export default EditClinicalProfile;
