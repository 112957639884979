import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InputBase, IconButton, Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

//icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import RecommendedAssessmentCardSkeleton from "../Skeletons/RecommendedAssessmentCardSkeleton";
import { FetchRecommendedAssessmentList } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { NoResults } from "../ClinicalNoteSVGs";

const sort_menu = [
  { name: "More questions" },
  { name: "Less questions" },
  { name: "Z-A" },
  { name: "Popularity" },
  { name: "Relevance" },
];

const RecommendationAssessmentAdd = ({ dataRecived, expert_info, disabled }) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetchAssesmentList = () => {
    FetchRecommendedAssessmentList()
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          setLoading(false);
          return;
        }
        setCardDetails(
          res.map((assessment) => ({
            ...assessment,
            is_selected: false,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
  };
  // Function to handle checkbox click
  const handleCheckboxClick = (index) => {
    const updatedDetails = [...cardDetails];
    updatedDetails[index].is_selected = !updatedDetails[index].is_selected;
    updatedDetails[index].recommended_by =
      expert_info?.Name;
    updatedDetails[index].recommended_expert_type = "";
    updatedDetails[index].recommended_date = new Date();
    updatedDetails[index].start_date = new Date().toISOString();


    // Update the cardDetails state
    setCardDetails(updatedDetails);

    // Update selectedItems array
    const updatedSelectedItems = updatedDetails
      .filter((item) => item.is_selected)
      .map((item) => item);
    setSelectedItems(updatedSelectedItems);
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
    fetchAssesmentList();
  };
  let arr = [];
  const handleClose = () => {
    setOpen(false);
    dataRecived(selectedItems);
  };
  const openSortBtn = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openSort = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Button
        sx={{
          width: "120px",
          backgroundColor: "transparent",
        }}
        disabled={disabled}
        onClick={() => { handleClickOpen() }}
        className="PrimaryCTA "
        id="add_assessment"
      >
        <AddOutlinedIcon />
        Add
      </Button>
      <Dialog
        className="recommendation-assessment"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex align-items-center justify-content-between"
        >
          <p className="fw-bold   p-2">Assessment</p>
          {/* <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              margin: "10px 0",
              width: 300,
            }}
            className="col-6"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search through Assessment"
              value={inputValue}
              onChange={handleChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <KeyboardVoiceOutlinedIcon />
            </IconButton>
          </Paper> */}
        </DialogTitle>
        <DialogContent className="d-flex w-100">
          {/* <DialogContentText className="d-flex justify-content-end my-2">
            <Button className=" border-0 text-black" onClick={openSort}>
              <FilterListIcon />
              <span style={{ fontSize: "20px", fontWeight: "400" }}>Sort</span>
            </Button>
            <Popover
              id={id}
              open={openSortBtn}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {sort_menu.map((menu, index) => (
                <Typography
                  sx={{
                    p: 2,
                    "&:hover": {
                      backgroundColor: "#F8F4FF",
                      color: "#73589B",
                    },
                  }}
                >
                  {menu.name}
                </Typography>
              ))}
            </Popover>
          </DialogContentText> */}


          {loading && <RecommendedAssessmentCardSkeleton />}
          {!loading && (cardDetails?.length < 1 ?
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              {NoResults}
              <p className="w-100 mt-2 d-flex justify-content-center align-items-center">
                No Assessment Found
              </p>
            </div>

            :
            <Grid container spacing={2} className="mt-2  ">
              {cardDetails?.map((data, index) => (
                <Grid item xs={12} sm={6} className="  ">
                  <div style={{
                    border: data?.is_selected ? "1px solid var(--primary-color-opacity50)" : "",
                    boxShadow: data?.is_selected ? "1.84px 3.68px 11.44px 0px var(--primary-color-opacity30)" : "1.84px 3.68px 11.44px 0px rgba(0, 0, 0, 0.20)"
                  }}
                    className={`d-flex flex-column h-100  rounded-3  p-3 `}
                  >
                    <div className="d-flex flex-row">  <img
                      height={145}
                      width={114}
                      src={data.backgroundImageUrl || "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"}
                      alt=""
                      style={{
                        borderRadius: "4.41px",
                        background:
                          "lightgray -45.151px -1.371px / 177.244% 100.931% no-repeat",
                      }}
                    />
                      <div className=" p-2 d-flex flex-column gap-1">
                        <p
                          className="fw-bold m-0"
                          style={{
                            color: "#323232",
                          }}
                        >
                          {data?.title}
                        </p>
                        <p className="fw-semibold m-0"
                          style={{
                            color: "#666",
                          }}
                        >
                          {data.questionsCount}{" "}question(s)
                        </p>
                        {/* <p className="fw-semibold"
                        style={{
                          color: "#666",
                        }}
                      >
                        Time - {data.time}
                      </p> */}
                        <p className="fw-bold m-0 lh-sm"
                          style={{
                            color: "#666",
                          }}
                        >
                          {data?.author?.length > 35 ? data.author.substring(0, 35) + "..." : data.author}
                        </p>

                      </div></div>
                    <div className="d-flex gap-2 justify-content-end">
                      <Checkbox
                        {...label}
                        checked={data.is_selected}
                        onChange={() => handleCheckboxClick(index)}
                      />
                    </div>

                  </div>
                </Grid>
              ))}
            </Grid>)}


        </DialogContent>
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Button
            sx={{
              width: "120px",
              backgroundColor: "transparent",
            }}
            className="PrimaryCTA "
            onClick={handleClose}
            id="added_assessment"
          >
            Done
          </Button>
        </DialogTitle>
      </Dialog>


    </div>
  );
};

export default RecommendationAssessmentAdd;
