import React, { useState, useEffect, useRef, useContext } from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppContext } from "../../../../context";
import Fade from "@mui/material/Fade";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { AccessTime, KeyboardArrowDownOutlined } from "@mui/icons-material";
import { TablePagination } from "@mui/material";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const HistoryPrescription = () => {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const [prescriptionHistoryModalOpen, setPrescriptionHistoryModalOpen] =
    useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get day and year
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

    // Array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // Get month name
    const month = monthNames[date.getMonth()];

    return `${day} ${month}, ${year}`;
  }
  const handlePrescriptionHistoryClick = (event) => {
    event.stopPropagation();
    setPrescriptionHistoryModalOpen(true);
  };

  const handleHistoryModalClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setPrescriptionHistoryModalOpen(false);
  };

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  const storedData = localStorage.getItem("Authorization");
  const token = storedData ? storedData.split("Bearer ")[1] : null;

  useEffect(() => {
    if (!expertClinicalNotesData?.headerData?.session_id) {
      return
    }
    const fetchPrescriptionMedicine = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/history?json_path=session_data.session_data.prescription&limit=30&current_session_id=${expertClinicalNotesData?.headerData?.session_id}`,
          {
            method: "POST",
            headers: {
              accept: "application/json",
              token: token,
              "Content-Type": "application/json",
              authenticationToken: Cookies.get("authDataJwt"),
            },
            body: JSON.stringify({}),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const resp = await response.json();

        setExpertClinicalNotesData((prevData) => ({
          ...prevData,
          prescriptionHistory: { ...prevData?.prescriptionHistory, medicine: jwtDecode(resp.data) },
        }));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPrescriptionLabtest = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/history?json_path=session_data.session_data.lab_test&limit=30&current_session_id=${expertClinicalNotesData?.headerData?.session_id}`,
          {
            method: "POST",
            headers: {
              accept: "application/json",
              token: token,
              "Content-Type": "application/json",
              authenticationToken: Cookies.get("authDataJwt"),
            },
            body: JSON.stringify({}),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const resp = await response.json();
        setExpertClinicalNotesData((prevData) => ({
          ...prevData,
          prescriptionHistory: { ...prevData?.prescriptionHistory, labtest: jwtDecode(resp.data) },
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrescriptionMedicine()
    fetchPrescriptionLabtest()
  }, [])

  const handleChangePage = (event, newPage) => {
    if (newPage >= 0 && newPage <= Math.ceil(expertClinicalNotesData?.prescriptionHistory?.medicine?.length / rowsPerPage)) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };
  return (
    <div className="d-flex gap-4">
      {/* <div
        style={{
          background: "rgba(115, 88, 155, 0.10)",
          color: "#FF6666",
        }}
        className="p-1 rounded-1"
      >
        <ReportProblemIcon />
      </div> */}

      <button
        style={{
          background: "var(--primary-accent-color)",
          color: "var(--primary-color)",
          height: "40px",
        }}
        className="d-flex align-items-center p-2 px-4 gap-2 rounded-2 border-0"
        onClick={handlePrescriptionHistoryClick}
      >
        <AccessTime />
        History
      </button>
      {/* Prescription History Modal */}

      <Dialog
        open={prescriptionHistoryModalOpen}
        onClose={handleHistoryModalClose}
        aria-labelledby="responsive-dialog-title"
        className="recommender-history-dialog "
        maxWidth="md"
      >   <DialogTitle
        id="responsive-dialog-title"
        style={{ background: "#F8F8F8" }}
      >
          Prescription History{" "}
        </DialogTitle>
        {expertClinicalNotesData?.prescriptionHistory?.medicine?.length < 1 || !expertClinicalNotesData?.prescriptionHistory?.medicine ?
          <DialogContent className="d-flex align-items-center justify-content-center font-inter fw-semibold">
            No History Data Available
          </DialogContent>
          :
          <DialogContent className="overflow-scroll">
            {expertClinicalNotesData?.prescriptionHistory?.medicine?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(
              (prescription, index) => (
                <>

                  <DialogContent className="my-3  summaryBody">
                    <div className="mb-3">
                      <p>
                        Prescribed on: {prescription?.prescribe_date && formatDate(prescription?.prescribe_date)}
                        by {prescription?.prescribed_by}{prescription?.expert_type && `(${prescription?.expert_type})`}
                      </p>
                    </div>
                    <h6>Note from Expert:</h6>
                    <div className="expertNote">{prescription?.expert_note}</div>
                    <div className="my-3">
                      <h6>Medication Details:</h6>
                    </div>
                    <div className="medicationAccordionContainer">
                      {prescription?.medicines?.prescribed_medicines?.map((data, index) => (
                        <Accordion
                          key={index}
                          sx={{
                            borderRadius: "6px",
                            border: "1px solid #D5D4D2",
                            margin: "10px 0",
                            width: "100%",
                            height: "fit-content",
                          }}
                          className=" h-auto"
                        >
                          <AccordionSummary
                            expandIcon={<KeyboardArrowDownOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >

                            <span className="fw-bold">{data?.drug}</span>

                          </AccordionSummary>

                          <AccordionDetails>
                            <p>{"Take " + data?.dosage ? data?.dosage : "" + " " + data?.route ? data?.route : "" + " " + data?.frequency ? data?.frequency : "" + " " + data?.form ? data?.form : ""}</p>

                            <h6>Instructions:</h6>

                            <p></p>
                            <h6>Duration of Prescription</h6>
                            <p>
                              {data?.duration}
                            </p>
                            <h6>Adverse Reactions/Allergies:</h6>
                            <p>{data?.adverse_reactions_allergies}</p>
                            <h6>Reason for Discontinuation or Change:</h6>
                            <p>{data?.reason_for_discontinuation_or_change}</p>
                          </AccordionDetails>
                        </Accordion>
                      ))}

                      <h6>Lab Test Details:</h6>
                      {expertClinicalNotesData?.prescriptionHistory?.labtest?.length > 0 && expertClinicalNotesData?.prescriptionHistory?.labtest[index]?.prescribed_lab_test?.
                        map((test, index) => (
                          <Accordion
                            key={index}
                            sx={{
                              borderRadius: "6px",
                              border: "1px solid #D5D4D2",
                              margin: "10px 0",
                              width: "100%",
                              overflowY: "none"
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <span className="fw-bold">{test?.test_name}</span>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>Sample Requirement: {test.sample_requirement}</p>
                              <h6>Purpose of the Test:</h6>
                              <p>{test.purpose_of_test}</p>
                              <TableContainer
                                component={Paper}
                                className="mx-auto text-center mt-4 mb-2"
                              >
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Result</TableCell>
                                      <TableCell>Reference Range</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {test?.results?.map((item, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{item.result}</TableCell>
                                        <TableCell>{item.reference_range}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <div className="mb-2 mt-4">
                                <span className="fw-semibold">
                                  *Clinician Interpretations:
                                </span>{" "}
                                {test?.clinician_interpretation}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </DialogContent>
                </>


              )
            )}
            <div>
              <TablePagination
                component="div"
                count={expertClinicalNotesData?.prescriptionHistory?.medicine?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </div>
          </DialogContent>}
      </Dialog>
    </div>
  );
};

export default HistoryPrescription;
