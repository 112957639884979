import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../../context";

// Component
import UserInfoSkeleton from "../Skeletons/UserInfoSkeleton";
import { handleBrokenImage, isAusDomain } from "../lib/utils";

function UserInfoPanel({ sendPing, pdf, downloadPDF }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(expertClinicalNotesData?.client || {}).length !== 0) {
      setLoading(false);
    }
  }, [expertClinicalNotesData?.client]);

  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Function to get the ordinal suffix for the day
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th"; // for 4th to 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}` ===
      "NaNth Invalid Date, NaN"
      ? formatDate(new Date())
      : `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  }

  function formatDate(dateString) {
    if (!dateString) return ""; // Return empty string if dateString is not provided

    const [year, month, day] = dateString.split("-");
    const formattedDate = `${getDayWithOrdinalSuffix(day)} ${getMonthName(
      month
    )} ${year}`;
    // console.log("dateString", formattedDate);
    return formattedDate;
  }

  function getMonthName(month) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(month) - 1];
  }

  function getDayWithOrdinalSuffix(day) {
    const dayNum = parseInt(day);
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder10 = dayNum % 10;
    const remainder100 = dayNum % 100;
    const suffix =
      suffixes[remainder10 < 4 && remainder100 > 20 ? remainder10 : 0];
    return `${day}${suffix}`;
  }

  return (
    <div
      className=" mt-4 rounded rounded-4 border"
      style={{ backgroundColor: "white" }}
    >
      {loading ? (
        <UserInfoSkeleton />
      ) : (
        <>
          <div className="d-flex flex-column flex-sm-row align-items-center p-2 px-sm-4 ">
            <div className="d-flex  gap-2  align-items-center">
              <img
                alt=""
                src={expertClinicalNotesData?.client?.image}
                onError={(e) => {
                  const newLink = handleBrokenImage(
                    expertClinicalNotesData?.client?.image
                  );
                  e.target.src = newLink;
                }}
                className="rounded rounded-circle "
                width={100}
                height={100}
                style={{
                  minHeight: "100px",
                  minWidth: "100px",
                  backgroundColor: "white",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                onClick={sendPing}
              />
            </div>
            <div className="p-2 p-sm-4 w-100 d-flex flex-column gap-sm-3 gap-1">
              <div className="d-flex align-items-sm-center justify-content-between flex-column flex-sm-row gap-sm-5 ">
                <h3 className="m-0">
                  {expertClinicalNotesData?.client?.name || "Client Name"}
                </h3>
              </div>

              {expertClinicalNotesData?.client?.age && (
                <p className="fs-5 m-0">
                  {expertClinicalNotesData?.client?.age} Years
                  {expertClinicalNotesData?.client?.gender && (
                    <>, {expertClinicalNotesData?.client?.gender}</>
                  )}
                </p>
              )}

              <div className="d-flex align-items-md-center flex-column flex-md-row gap-1 gap-md-3">
                <span className="d-flex align-items-center gap-2">
                  {expertClinicalNotesData?.client?.session_date && (
                    <img
                      width={24}
                      src="/assets/img/clinical_notes_assets/frame-calender.png"
                    />
                  )}
                  {formatDate(expertClinicalNotesData?.client?.session_date)}
                </span>

                {expertClinicalNotesData?.client?.phone && (
                  <span className="d-flex align-items-center gap-2">
                    <img
                      width={24}
                      src="/assets/img/clinical_notes_assets/Frame-phone.png"
                    />
                    {expertClinicalNotesData?.client?.phone}
                  </span>
                )}

                {expertClinicalNotesData?.client?.email && (
                  <span className="d-flex align-items-center gap-2">
                    {" "}
                    <img
                      width={24}
                      src="/assets/img/clinical_notes_assets/Frame-email.png"
                    />
                    {expertClinicalNotesData?.client?.email}
                  </span>
                )}
              </div>
            </div>
          </div>
          {(expertClinicalNotesData?.client?.his_number ||
            expertClinicalNotesData?.client?.social_security_number ||
            expertClinicalNotesData?.client?.address) && (
            <div className="d-flex  justify-content-between align-items-center p-sm-4 p-2  border-top">
              <div className="d-flex align-items-center flex-wrap gap-sm-3 gap-md-5 gap-2 ">
                {expertClinicalNotesData?.client?.his_number &&
                  !isAusDomain() && (
                    <span>
                      <p className="m-0" style={{ color: "#807C75" }}>
                        HIS Number
                      </p>
                      <p className="m-0">
                        {expertClinicalNotesData?.client?.his_number}
                      </p>
                    </span>
                  )}

                {expertClinicalNotesData?.client?.social_security_number && (
                  <span className="">
                    <p className="m-0" style={{ color: "#807C75" }}>
                      {isAusDomain()
                        ? "Medicare Number / IHI Number"
                        : "Social Security"}
                    </p>
                    <p className="m-0">
                      {expertClinicalNotesData?.client?.social_security_number}
                    </p>
                  </span>
                )}
                {expertClinicalNotesData?.client?.address && (
                  <span>
                    <p className="m-0" style={{ color: "#807C75" }}>
                      Address
                    </p>
                    <p className="m-0">
                      {[
                        expertClinicalNotesData?.client?.address?.address_line,
                        expertClinicalNotesData?.client?.address?.state,
                        expertClinicalNotesData?.client?.address?.country,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  </span>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default UserInfoPanel;
