// GTMComponent.js
import React, { useEffect, useState } from "react";
import { isAusDomain } from "./components/expertDashboard/clinicalNotesClinicalRoute/lib/utils";

const GTMComponent = () => {
  const [gtmId, setGtmId] = useState(null);
  const [gId, setGId] = useState(null);

  useEffect(() => {
   

    // Determine GTM ID based on subdomain
    if (
     isAusDomain()
    ) {
      setGtmId("GTM-MVKNCM4H");
      setGId("G-6R9WK18T5N");
    } else {
      setGtmId("GTM-M286P4S");
      setGId("G-28HL9LHXGG");
    }
  }, []);

  useEffect(() => {
    if (gtmId && gId) {
      // Inject the GA script dynamically
      const gaScript = document.createElement("script");
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gId}`;
      gaScript.async = true;
      document.head.appendChild(gaScript);

      // Initialize dataLayer for GA
      window.dataLayer = window.dataLayer || [];

      // Convert `gtag` function to a function expression to avoid block-scoping error
      const gtag = (...args) => {
        window.dataLayer.push(args);
      };

      gtag("js", new Date());
      gtag("config", gId);

      // Inject the GTM script dynamically
      const gtmScript = document.createElement("script");
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);

      // Initialize dataLayer for GTM
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
    }
  }, [gtmId, gId]);

  return (
    <>
      {/* Conditionally render noscript for GTM with appropriate ID */}
      {gtmId && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
            title="gtm-noscript"
          ></iframe>
        </noscript>
      )}
    </>
  );
};

export default GTMComponent;