import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isAusDomain } from "../clinicalNotesClinicalRoute/lib/utils";

const helpfulPDFs = [
  {
    id: 1,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/01.%20Creating%20a%20New%20Clinical%20Note.pdf",
    name: "Creating a New Clinical Note",
  },
  {
    id: 2,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/02.%20Creating%20a%20New%20Client%20Profile.pdf",
    name: "Creating a New Client Profile",
  },
  {
    id: 3,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/03.%20Using%20Voice%20Transcription.pdf",
    name: "Using Voice Transcription",
  },
  {
    id: 4,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/04.%20Uploading%20Documents.pdf",
    name: "Uploading Documents",
  },
  {
    id: 5,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/05.%20Navigating%20a%20Clinical%20Note.pdf",
    name: "Navigating a Clinical Note",
  },
  {
    id: 6,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/06.%20Recommending%20Resources%20to%20Patients.pdf",
    name: "Recommending Resources to Patients",
  },
  {
    id: 7,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/07.%20Editing%20Your%20Profile.pdf",
    name: "Editing Your Profile",
  },
  {
    id: 8,
    link: "https://images.unitedwecare.com/odoo/open/webapp/co-pilot/helpcenter/08.%20Contacting%20Support.pdf",
    name: "Contacting Support",
  },
];

const ausHelpfulPDFs = [
  {
    id: 1,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F01.%20Creating%20a%20New%20Clinical%20Note.pdf?alt=media&token=b76f4a98-f51e-4dc0-8b8f-7db10c4c326b",
    name: "Creating a New Clinical Note",
  },
  {
    id: 2,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F02.%20Creating%20a%20New%20Client%20Profile.pdf?alt=media&token=46c35103-1f8b-45d8-92c8-68bddd81a546",
    name: "Creating a New Client Profile",
  },
  {
    id: 3,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F03.%20Using%20Voice%20Transcription.pdf?alt=media&token=99d357b5-f973-4875-b19d-328f5b849d7c",
    name: "Using Voice Transcription",
  },
  {
    id: 4,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F04.%20Uploading%20Documents.pdf?alt=media&token=27c82a65-450f-4942-9f97-f0b3b2a6f0f8",
    name: "Uploading Documents",
  },
  {
    id: 5,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F05.%20Navigating%20a%20Clinical%20Note.pdf?alt=media&token=710059e2-9d87-4851-8ff8-c0bab6c5f866",
    name: "Navigating a Clinical Note",
  },
  {
    id: 6,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F06.%20Recommending%20Resources%20to%20Patients.pdf?alt=media&token=c8e35f1b-569b-49bc-82aa-e9ecdfc35bfe",
    name: "Recommending Resources to Patients",
  },
  {
    id: 7,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F07.%20Editing%20%20Your%20Profile.pdf?alt=media&token=233fa006-fe91-45da-a8b0-9a0019742b5a",
    name: "Editing Your Profile",
  },
  {
    id: 8,
    link: "https://firebasestorage.googleapis.com/v0/b/images.unitedwecare.com/o/odoo%2Fopen%2Fwebapp%2Fco-pilot%2Fhelpcenter%2Fasksam%2F08.%20Contacting%20Support.pdf?alt=media&token=95365ee9-3754-47ea-b7ce-ad2aeb2e1212",
    name: "Contacting Support",
  },
];

function HelpCenterDocuments() {
  const navigate = useNavigate();
  return (
    <div className="mt-3 ExpertHelpCenter--Dashboard">
      <div className="ExpertHelpCenter--Header d-flex justify-content-between align-items-center">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => {
                navigate("/clinical/help-center");
              }}
              role="button"
              color="text.primary"
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
              }}
              className="d-flex text-secondary align-items-center justify-content-between gap-2 "
            >
              Help Center
            </Link>

            <Typography
              sx={{ fontWeight: "500", fontSize: "20px" }}
              color="text.primary"
            >
              Documents
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="d-none">
        <Typography
          sx={{ fontWeight: "500", fontSize: "24px" }}
          className="mt-3"
          color="text.primary"
        >
          Documentation & Guides
        </Typography>
        <p style={{ fontSize: "1rem" }} className="mt-3 mb-5">
          Find manuals, how-to PDFs, white papers, case studies, and other
          resources to help you use our services.
        </p>
      </div>

      <div className="ExpertHelpCenter--documents">
        {isAusDomain()
          ? ausHelpfulPDFs.map((item) => (
              <div
                className="cursor-pointer  shadow-sm help_center_documents"
                key={item.id}
                onClick={() => window.open(item.link)}
                data-help-center-document={item.link}
                style={{
                  cursor: "pointer",
                  background: "white",
                  borderRadius: "5px",
                  maxWidth: "160px",
                }}
              >
                <div
                  style={{ background: "#D0D5DD", borderRadius: "5px 5px 0 0" }}
                  className="w-100 py-3 pe-3 d-flex align-items-center justify-content-center"
                >
                  <img
                    src={"/assets/img/help-center-assets/Pdf-type.png"}
                    alt="..."
                  />
                </div>
                <div className="py-3 px-2">
                  <span
                    className=" fw-semibold line-clamp "
                    style={{ fontSize: "0.75rem", lineHeight: "120%" }}
                  >
                    {item.name}
                  </span>
                </div>
              </div>
            ))
          : helpfulPDFs.map((item) => (
              <div
                className="cursor-pointer  shadow-sm help_center_documents"
                key={item.id}
                onClick={() => window.open(item.link)}
                data-help-center-document={item.link}
                style={{
                  cursor: "pointer",
                  background: "white",
                  borderRadius: "5px",
                  maxWidth: "160px",
                }}
              >
                <div
                  style={{ background: "#D0D5DD", borderRadius: "5px 5px 0 0" }}
                  className="w-100 py-3 pe-3 d-flex align-items-center justify-content-center"
                >
                  <img
                    src={"/assets/img/help-center-assets/Pdf-type.png"}
                    alt="..."
                  />
                </div>
                <div className="py-3 px-2">
                  <span
                    className=" fw-semibold line-clamp "
                    style={{ fontSize: "0.75rem", lineHeight: "120%" }}
                  >
                    {item.name}
                  </span>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}

export default HelpCenterDocuments;
