import { UserDetailsService } from '../components/core/_request';
import Cookies from "js-cookie";
import { getSubDomain } from '../components/expertDashboard/clinicalNotesClinicalRoute/lib/utils';
export const useAuth = () => ({
    currentUser: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : null,
    isLogedIn: async () => {
        const Authorization = localStorage.getItem("Authorization");
        if (Authorization) {
            var UserDetails = await UserDetailsService().then((resp) => resp.data);
            // console.log("UserDetails", UserDetails);
            return UserDetails?.ResponseCode == 200 ?? false;
        }
        return false;
    },
    userType: {
        EXPERT: 3,
        ENDUSER: 4
    },
    setCurrentUser: (profile) => localStorage.setItem("profile", JSON.stringify(profile)),
    login: (data) => {
        localStorage.setItem("Authorization", data.token_type + " " + data.access_token);
        let now = new Date();
        let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
        let firstName = data?.userdetails?.Name.split(" ").slice(0, 1).join("");
        document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=${getSubDomain()};path=/;`;
        document.cookie = `userType=${data?.userdetails?.UserType};expires=${oneYearFromNow.toUTCString()};domain=${getSubDomain()};path=/;`;
    },
    logout: () => {
        localStorage.removeItem("Authorization");
        localStorage.removeItem("profile");
        localStorage.removeItem("disclaimer");
        sessionStorage.removeItem("UserState")
       
        if (Cookies.get("authToken")) {
            Cookies.remove("authToken", {
                domain: `${getSubDomain()}`,
                path: "/",
                // secure: true,
                HttpsOnly: true,
            });
        }
        if (Cookies.get("authUserRole")) {
            Cookies.remove("authUserRole", {
                domain: `${getSubDomain()}`,
                path: "/",
                // secure: true,
                HttpsOnly: true,
            });
        }
        let now = new Date();
        let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
        document.cookie = `userType=${null};expires=${oneYearFromNow.toUTCString()};domain=${getSubDomain()};path=/;`;
        document.cookie = `displayName=${null};expires=${oneYearFromNow.toUTCString()};domain=${getSubDomain()};path=/;`;
    },
    updateMobileNo: (mobileNumber) => {
        var profileJSON = localStorage.getItem("profile");
        var profile = JSON.parse(profileJSON);
        profile.MobileNo = mobileNumber;
        var updatedProfileJSON = JSON.stringify(profile);
        localStorage.setItem("profile", updatedProfileJSON);
    },
    updateLocation: (location) => {
        var profileJSON = localStorage.getItem("profile");
        var profile = JSON.parse(profileJSON);
        profile.Location = location;
        var updatedProfileJSON = JSON.stringify(profile);
        localStorage.setItem("profile", updatedProfileJSON);
    }
});

