import { ArrowBack, Visibility } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { NoDataSvg } from "../../../clinicalNotesClinicalRoute/ClinicalNoteSVGs";
import ScreenerDetailsView from "../ScreenerDetailsView";

function Screeners({ clientDetails }) {
  const [mergedClientScreeners, setMergedClientScreeners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [screenerDetalis, setScreenerDetalis] = useState(null);
  // Define the sorting function
  const sortByDate = (a, b) => {
    // Extract score_date or create_date from each item
    let dateA = a.score_date || a.create_date;
    let dateB = b.score_date || b.create_date;

    // Convert to date objects
    dateA = new Date(dateA);
    dateB = new Date(dateB);

    // Compare the dates
    return dateB - dateA; // This will sort in ascending order
  };

  //Fetch Client Screeners
  useEffect(() => {
    if (!clientDetails?.id) {
      // If checkClientID is blank, return early
      return;
    }

    ///ADDED API IN CLIENTAPIS
    ///IMPLEMENTATION PENDING

    const postClientScreenersData = async () => {
      try {
        const profile = JSON.parse(localStorage.getItem("profile"));
        const screenerBody = {
          expert_id: profile?.UserId,
          user_id: clientDetails?.id,
          // user_id: currentUser?.UserId,
        };

        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/assignments`,
          {
            method: "POST",
            headers: {
              "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(screenerBody),
          }
        );
        const postResponse = await response.json();

        const { assignments } = await postResponse.data;
        const { worksheets } = await postResponse.data;

        // Modify worksheets to include score_date and type
        const modifiedWorksheets = worksheets.map((worksheet) => ({
          ...worksheet,
          score_date: worksheet.create_date,
          type: "QNA", // Copy create_date to score_date
        }));

        // Modify assignments to include score_date
        const modifiedAssignments = assignments.map((worksheet) => ({
          ...worksheet,
          type: "Options",
        }));

        // Merge the arrays
        const mergedClientScreeners = [
          ...modifiedAssignments,
          ...modifiedWorksheets,
        ];
        // Sort the merged array
        const sortedMergedClientScreeners =
          mergedClientScreeners.sort(sortByDate);

        setMergedClientScreeners(sortedMergedClientScreeners);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientScreenersData();
  }, [clientDetails?.id]);

  const handleViewClick = (type, name, id, clientId, clientDetails) => {
    setScreenerDetalis({
      type,
      name,
      id,
      clientId,
      clientDetails,
    });
  };

  return (
    <div>
      {screenerDetalis?.clientId && (
        <IconButton onClick={() => setScreenerDetalis({})}>
          <ArrowBack />
        </IconButton>
      )}
      <div className="Tab--Panel Screeners--Panel">
        {!screenerDetalis?.clientId && loading ? (
          Array.from(new Array(10)).map((_, index) => (
            <div
              style={{
                width: "100%",
                height: "70px",
                backgroundColor: "rgb(0,0,0,0.07)",
                padding: "10px",
                borderRadius: "10px",
              }}
              className="d-flex justify-content-between"
            >
              <div className="d-flex flex-column gap-2">
                <Skeleton
                  variant="rounded"
                  height={18}
                  width="20vw"
                  sx={{ borderRadius: "10px" }}
                />
                <Skeleton
                  variant="rounded"
                  height={18}
                  width="15vw"
                  sx={{ borderRadius: "10px" }}
                />
              </div>

              <div className="CTA--Container d-flex gap-2 align-items-center">
                <Skeleton
                  variant="rounded"
                  height={38}
                  width={86}
                  sx={{ borderRadius: "20px" }}
                />
              </div>
            </div>
          ))
        ) : !screenerDetalis?.clientId && mergedClientScreeners?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Submitted On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {mergedClientScreeners?.map((item) => (
                <TableRow hover className="">
                  {/* Screener Title */}
                  <TableCell>
                    <Typography variant="body2">{item.name}</Typography>
                  </TableCell>

                  {/* Submitted On */}
                  <TableCell>
                    <Typography variant="body2">
                      {dayjs(item.score_date).format("DD MMM YYYY")}
                    </Typography>
                  </TableCell>

                  {/* Action Buttons (View & Print) */}
                  <TableCell>
                    <Button
                      variant="text"
                      sx={{
                        borderRadius: "20px", // Rounded but not too much
                        bgcolor: "#f5f5f5",
                        color: "#414141",
                        fontSize: "14px", // 14px as in the default theme
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#f5f5f5",
                        },
                      }}
                      className="d-flex justify-content-center align-items-center gap-2 rounded-3 px-3 "
                      onClick={() =>
                        handleViewClick(
                          item?.type,
                          item?.name,
                          item?.id,
                          clientDetails?.id,
                          clientDetails
                        )
                      }
                    >
                      <Visibility />
                      View
                    </Button>
                    {/* Uncomment the print button if you want to add print functionality later */}
                    {/* <IconButton>
                  <LocalPrintshopOutlinedIcon />
                </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          !screenerDetalis?.clientId && (
            <div className="NoDataAvailable">
              {NoDataSvg}
              <p> No Screeners Available</p>
            </div>
          )
        )}

        {screenerDetalis?.clientId && (
          <>
            {" "}
            <ScreenerDetailsView
              type={screenerDetalis?.type}
              title={screenerDetalis?.name}
              screenerID={screenerDetalis?.id}
              clientID={screenerDetalis?.clientId}
              clientDetails={screenerDetalis?.clientDetails}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Screeners;
