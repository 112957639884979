export const convertBlobToBase64 = (blob) => {
    //console.log("blob", blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};


export const handleBrokenImage = (imgLink) => {
    const imgLinkSplit = imgLink.split("https://");
    if (imgLinkSplit.length > 1) {
        const updatedLink = "https://" + imgLinkSplit[imgLinkSplit.length - 1];
        return updatedLink;
    } else {
        return "https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp"
    }
}


export const isAusDomain = () => {
    const domains = process.env.REACT_APP_LIMIT_DOMAIN.split(','); // Split domains into an array
    const isMatchingDomain = domains.some(domain => window.location.host.includes(domain));
    return isMatchingDomain;
}


export const getSubDomain = () => {
    const host = window.location.host;
    const domainPattern = /([a-zA-Z0-9-]+)\.(com|ai|com.au)$/; // Match domain like "unitedwecare.com" or "ask-sam.ai"
  
    const match = host.match(domainPattern);
  
    if (match) {
      return `.${match[1]}.${match[2]}`; // Returns "unitedwecare.com" or "ask-sam.ai"
    }
    return `.${host}`;
  };