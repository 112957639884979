import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import { getSubDomain, isAusDomain } from "../clinicalNotesClinicalRoute/lib/utils";

function ClinicalUserUnauthorized() {
  const [counter, setCounter] = useState(30);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleClick = () => {
    if (isAusDomain()) {
      window.location.href = `https://www${getSubDomain()}`;
    } else {
      window.location.href = `https://www${getSubDomain()}/clinical-co-pilot/`;
    }
  };
  useEffect(() => {
    if (currentUser) {
      navigate("/clinical/home");
    }
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);
    if (counter === 0) {
      clearInterval(intervalId);
      handleClick();
    }
    return () => {
      clearInterval(intervalId); // Cleanup the interval if the component unmounts
    };
  }, [counter]);

  return (
    <div
      style={{ height: "100vh" }}
      className="w-full d-flex gap-3 p-2 justify-content-center align-items-center flex-column"
    >
      <img width={300} src={process.env.REACT_APP_CLIENT_IMAGE} />
      <div className="d-flex flex-column gap-3 align-items-center">
        <p className=" fw-bold fs-1">Unauthorized Access!</p>
      </div>

      <p className=" text-center col-11 col-lg-7 fs-5">
        You do not have the necessary permissions to access the Clinical
        Co-Pilot dashboard. If you think this is a mistake, please contact our{" "}
        <a
          style={{ color: "var(--primary-color)" }}
          href={`mailto:${process.env.REACT_APP_CLIENT_EMAIL}`}
        >
          support team{" "}
        </a>
      </p>
      <p className=" text-center col-11 col-lg-7 ">
        Redirecting to Clinical Co-Pilot landing page in:{" "}
        <span className="fw-bold">{counter} seconds</span>
      </p>

      <Button
        onClick={handleClick}
        variant="contained"
        sx={{ minWidth: "300px", fontSize: "20px" }}
        className="PrimaryCTA"
      >
        Redirect to Clinical Co-Pilot
      </Button>
    </div>
  );
}

export default ClinicalUserUnauthorized;
