import jwtDecode from "jwt-decode";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
function SessionOverview({ clientDetails }) {
  const navigate = useNavigate();
  const [clientData, setClientData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (clientDetails?.id) {
      const storedData = localStorage.getItem("Authorization");
      const profile = JSON.parse(localStorage.getItem("profile"));
      const token = storedData ? storedData?.split("Bearer ")[1] : null;
      const FetchClinicalNotesClients = async (
        professional_id,
        client_id,
        session
      ) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/list?professional_id=${professional_id}&client_id=${client_id}&status=all`,
            {
              method: "GET",
              headers: {
                "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                "Content-Type": "application/json",
                token: token,
                authenticationToken: Cookies.get("authDataJwt"),
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const resp = await response.json();
          if (resp.status === "success") {
            const clientData = jwtDecode(resp.data);
            setClientData(clientData);
          } else {
            enqueueSnackbar(resp.message, { variant: "info" });
          }
        } catch (error) {
          console.error(error);
        }
      };
      FetchClinicalNotesClients(profile.UserId, clientDetails?.id);
    }
  }, [clientDetails?.id]);
  return (
    <div>
      {" "}
      <h5 className="my-2 pb-2">Session overview</h5>
      <div
        style={{
          background: "#fff",
          borderRadius: "10px",
        }}
        className="border p-2 p-lg-3 d-flex flex-column gap-3"
      >
        {clientData?.map((item, index) => (
          <div
            role="button"
            onClick={() =>
              navigate(
                `/clinical/home?view=view&clinicalId=${item?._id}&noteType=${item?.session_type}`
              )
            }
            style={{
              background: "var(--primary-accent-color)",
            }}
            className=" p-3 rounded-3"
            key={index}
          >
            <div className=" fw-semibold d-flex mb-2 justify-content-between ">
              <p className="m-0">{item?.session_date}</p>{" "}
              <p className="m-0">{item?.status}</p>
            </div>

            <p className="m-0">
              {item?.session_overview || "No session overview available "}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SessionOverview;
