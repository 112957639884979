import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { AppContext } from "../../../../context";
import { Skeleton } from "@mui/material";
import { NoDataSvg } from "../../clinicalNotesClinicalRoute/ClinicalNoteSVGs";

function Credits() {
    const { enqueueSnackbar } = useSnackbar();
    const [creditsRow, setCreditsRow] = useState([]);
    const [loading, setLoading] = useState(true);
    const { setCredits } = useContext(AppContext);
    const fetchCredits = async () => {
        const storedData = JSON.parse(localStorage.getItem("profile"));
        const response = await fetch(
            `https://integrations.uwc.world/users/${storedData?.UserId}/credits`,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.status !== 200) {
            enqueueSnackbar("An error occurred while fetching credits", {
                variant: "error",
            });
            return;
        }
        const data = await response.json();
        if (data?.status_code === 200) {
            setCreditsRow(data?.data?.credits);
            setCredits(data?.data?.available_credits);
            setLoading(false);
        }

    };
    useEffect(() => {
        fetchCredits();
    }, []);

    return (
        <>
            <TableContainer className=" border rounded-3 " sx={{ marginTop: "20px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Sr. No</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Credits</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>

                            <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Remarks</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <>
                                {Array.from(new Array(10)).map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem", width: "5vw" }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem", width: "5vw" }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem", width: "5vw" }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem", width: "5vw" }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem", width: "5vw" }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ) : (
                            <>
                                {creditsRow?.length > 0 &&
                                    creditsRow.map((row, index) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell sx={{ fontWeight: "bold" }}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    fontWeight: "bold",
                                                    color:
                                                        row.operation === "credit"
                                                            ? "rgba(7, 148, 85, 1)"
                                                            : "rgba(217, 45, 32, 1)",
                                                }}
                                            >
                                                {row.operation === "credit" ? "+" : "-"} {row.amount}
                                            </TableCell>
                                            <TableCell>
                                                {dayjs(row?.date).format("YYYY-MM-DD")}
                                            </TableCell>
                                            <TableCell align="">
                                                <span
                                                    className="px-4 py-2 fw-bold rounded-pill"
                                                    style={{
                                                        background:
                                                            row.operation === "credit"
                                                                ? "rgba(169, 239, 197, 1)"
                                                                : "rgba(254, 205, 202, 1)",
                                                        color:
                                                            row.operation === "credit"
                                                                ? "rgba(7, 148, 85, 1)"
                                                                : "rgba(217, 45, 32, 1)",
                                                    }}
                                                >
                                                    {row.operation === "credit" ? "Credited" : "Debited"}
                                                </span>
                                            </TableCell>
                                            <TableCell>{row?.remark}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </>
                        )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {!loading && (!creditsRow || creditsRow?.length === 0) && <div className="w-full my-4 d-flex align-items-center justify-content-center flex-column gap-4">

                <div className="NoDataAvailable">
                    {NoDataSvg}
                    <p>   No Credits yet</p>
                </div>
            </div>}

        </>
    );
}

export default Credits;
