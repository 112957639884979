import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Typography,
  Link,
  Breadcrumbs,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Container, Row, Col, Table } from "react-bootstrap";
import { TGAdata } from "../../TestData"; // Assuming TGAdata is imported from TestData

const ExpertTGAReport = () => {
  // State for date range selection and THC threshold
  const [startDateRange, setStartDateRange] = useState("2024-05-01");
  const [endDateRange, setEndDateRange] = useState("2024-10-01");
  const [thcThreshold, setThcThreshold] = useState(0); // THC dose threshold
  const [dateSelection, setDateSelection] = useState("custom"); // Track predefined date range selection

  // State for calculated patient data
  const [patientData, setPatientData] = useState({
    totalPatients: {
      "Category 1": 0,
      "Category 2": 0,
      "Category 3": 0,
      "Category 4": 0,
      "Category 5": 0,
    },
    newPatients: {
      "Category 1": 0,
      "Category 2": 0,
      "Category 3": 0,
      "Category 4": 0,
      "Category 5": 0,
    },
    totalPatientCount: 0,
    newPatientCount: 0,
  });

  // Utility function to parse date
  const parseDate = (dateString) => new Date(dateString);

  // Function to calculate new patients and total patients based on the date range and THC dose threshold
  const calculatePatientData = () => {
    // Initialize counts for total and new patients
    let totalPatients = {
      "Category 1": 0,
      "Category 2": 0,
      "Category 3": 0,
      "Category 4": 0,
      "Category 5": 0,
    };

    let newPatients = {
      "Category 1": 0,
      "Category 2": 0,
      "Category 3": 0,
      "Category 4": 0,
      "Category 5": 0,
    };

    // Convert selected date ranges to Date objects
    const startDateFilter = parseDate(startDateRange);
    const endDateFilter = parseDate(endDateRange);

    // Initialize patient counts
    let totalPatientCount = 0;
    let newPatientCount = 0;

    // Iterate over patient data
    TGAdata.forEach((patient) => {
      const visitDate = parseDate(patient.visitDate);
      const startDate = parseDate(patient.startDate);
      const { thcDose, category } = patient;

      // Check if THC dose is above the threshold
      if (thcDose >= thcThreshold) {
        // Check if visit date falls within the selected date range
        if (visitDate >= startDateFilter && visitDate <= endDateFilter) {
          newPatients[category] += 1; // Count as new patient
        } else {
          totalPatients[category] += 1; // Count as total patient
        }
      }
      // Check if the patient is new (started within date range)
      if (startDate >= startDateFilter && startDate <= endDateFilter) {
        newPatientCount += 1; // Increase new patient count
      } else {
        totalPatientCount += 1; // Increase total patient count
      }
    });

    return { totalPatients, newPatients, totalPatientCount, newPatientCount };
  };

  // Function to handle date range selection
  const handleDateSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setDateSelection(selectedValue);

    const today = new Date();
    let startDate;
    let endDate;

    switch (selectedValue) {
      case "1month":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 1);
        endDate = today;
        break;
      case "3months":
        startDate = new Date(today);
        startDate.setMonth(today.getMonth() - 3);
        endDate = today;
        break;
      case "1year":
        startDate = new Date(today);
        startDate.setFullYear(today.getFullYear() - 1);
        endDate = today;
        break;
      case "custom":
      default:
        startDate = parseDate(startDateRange);
        endDate = parseDate(endDateRange);
        break;
    }

    setStartDateRange(startDate.toISOString().split("T")[0]);
    setEndDateRange(endDate.toISOString().split("T")[0]);
  };

  // Calculate patient data whenever the state changes
  useEffect(() => {
    const data = calculatePatientData();
    setPatientData(data);
  }, [startDateRange, endDateRange, thcThreshold]); // Dependency array

  return (
    <div className="my-3 ClientDashboard">
      <div className="ClientDashboard--Header d-flex">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              id="clients-breadcrumb"
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
                color: "text.primary",
              }}
              className={`d-flex align-items-center justify-content-between gap-2 `}
            >
              TGA Report
            </Link>
          </Breadcrumbs>
        </div>{" "}
        <Container className="mt-4">
          {/* Predefined Date Range Selection */}
          <Row className="mb-4">
            <Col
              md={12}
              className="d-flex justify-content-lg-between align-items-center flex-wrap gap-4"
            >
              {" "}
              <div style={{ minWidth: "300px" }} className="d-flex gap-4">
                <Col className=" text-center">
                  <Typography className="" variant="h6">
                    Total Patients
                  </Typography>
                  <Typography variant="h4">
                    {patientData.totalPatientCount}
                  </Typography>
                </Col>
                <Col className="text-center">
                  <Typography variant="h6">New Patients </Typography>
                  <Typography variant="h4">
                    {patientData.newPatientCount}
                  </Typography>
                </Col>
              </div>
              <FormControl size="small" sx={{ minWidth: "250px" }}>
                <InputLabel id="date-selection-label">
                  Select Date Range
                </InputLabel>
                <Select
                  labelId="date-selection-label"
                  value={dateSelection}
                  label="Select Date Range"
                  onChange={handleDateSelectionChange}
                >
                  <MenuItem value="1month">Last 1 Month</MenuItem>
                  <MenuItem value="3months">Last 3 Months</MenuItem>
                  <MenuItem value="1year">Last 1 Year</MenuItem>
                  <MenuItem value="custom">Custom Range</MenuItem>
                </Select>
              </FormControl>
              {/* Date Range Selection for Custom Date */}
              {dateSelection === "custom" && (
                <div className="d-flex gap-2 gap-sm-4">
                  <FormControl>
                    <TextField
                      size="small"
                      label="Start Date Range"
                      type="date"
                      value={startDateRange}
                      onChange={(e) => setStartDateRange(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>

                  <FormControl>
                    <TextField
                      label="End Date Range"
                      size="small"
                      type="date"
                      value={endDateRange}
                      onChange={(e) => setEndDateRange(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </div>
              )}
            </Col>
          </Row>
          {/* Summary Section for New and Total Patients */}
          <Row className="mb-4"></Row>

          {/* Display Patients by Category */}
          <Row className="mb-4">
            <Col>
              {" "}
              {/* THC Threshold Selection */}
              <FormControl size="small" sx={{ minWidth: "150px" }}>
                <InputLabel id="thc-threshold-label">
                  Threshold Dosage
                </InputLabel>
                <Select
                  labelId="thc-threshold-label"
                  value={thcThreshold}
                  label="Threshold Dosage"
                  onChange={(e) => setThcThreshold(parseInt(e.target.value))}
                >
                  {/* THC dose options from 0 to 100 mg in increments of 5 */}
                  {[...Array(21).keys()].map((i) => {
                    const dose = i * 5;
                    return (
                      <MenuItem key={dose} value={dose}>
                        {dose} mg THC/day
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Typography marginBottom={3} variant="h5" align="center">
                Patients by Category
              </Typography>
              <TableContainer
                className=" border rounded-3 "
                sx={{ marginTop: "20px" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className={`ClientDetails--TableRow`}>
                      <TableCell
                        className="Table--Sessions"
                        sx={{ fontWeight: "bold" }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        className="Table--Sessions"
                        sx={{ fontWeight: "bold" }}
                      >
                        Total Patients
                      </TableCell>
                      <TableCell
                        className="Table--Sessions"
                        sx={{ fontWeight: "bold" }}
                      >
                        New Patients
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(patientData.totalPatients).map(
                      ([category, count]) => (
                        <TableRow
                          className={`ClientDetails--TableRow`}
                          key={category}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell className="Table--Name">
                            {category}
                          </TableCell>
                          <TableCell className="Table--Sessions">
                            {count}
                          </TableCell>
                          <TableCell className="Table--Sessions">
                            {patientData.newPatients[category]}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ExpertTGAReport;
